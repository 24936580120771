import React, { useState, useEffect, useCallback, useRef } from 'react';
import { MODE, SERVICE_URL, Styles } from '../../controls/Shared';

export function ChargesAndPayments(props) {

    const [inEditMode, setInEditMode] = useState({
        status: false,
        rowKey: null
    });

    const { SelectedGuest, userInfo } = props;
    const [theGuest ] = useState(SelectedGuest);
    const [chargeData, setChargeData] = useState(null);
    const [paymentData, setPaymentData] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [rate, setRate] = useState(0.00);
    const [notes, setNotes] = useState("");

    const updateInventory = (body) => {
      const url = SERVICE_URL + "/updateCharge";
      
          fetch(url, {
              method: 'POST',
              body: body,
              headers: {
                  'Content-Type': 'application/json'
              }
              }).then(json => {
              // reset inEditMode and unit price state values
              onCancel();

              // fetch the updated data
              GetHousingCharges();
          })
    }

    const deleteInventory = (body) => {
      const url = SERVICE_URL + "/deleteChargeOrPayment";
      
          fetch(url, {
              method: 'POST',
              body: body,
              headers: {
                  'Content-Type': 'application/json'
              }
              }).then(json => {
              if (json.status !== 200) {
                alert("There has been an issue with the deletion. Please see the Event Logs for more detail. ")
              } else {
                //alert("Deletion complete!")
                // fetch the updated data
                GetHousingCharges();
              }              
          })
    }

    const onEdit = ({id, quantity, rate, currentNotes}) => {
      setInEditMode({
          status: true,
          rowKey: id
      })
      setQuantity(quantity);
      setRate(rate);
      setNotes(currentNotes);
    }

    const onSave = ({id, quantity, rate, notes }) => {
      const body = JSON.stringify({
          "ChargeId" : id,
          "Quantity" : parseInt(quantity),
          "Rate" : parseFloat(rate),
          "Notes" : notes === undefined ? "" : notes,
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
      });
      updateInventory(body);
    } 

    const onDelete = ({ id, type }) => {

      if(window.confirm("Are you certain you want to delete this entry?")) {
        const body = JSON.stringify({
          "Id" : id,
          "Type" : type,
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });
        deleteInventory(body);
      }      
    } 

    const onCancel = () => {
      // reset the inEditMode state value
      setInEditMode({
          status: false,
          rowKey: null
      })
      // reset the unit price state value
      setNotes(notes);
    }

    const notesInputRef = useRef(null);
    const quantityInputRef = useRef(null);
    const rateInputRef = useRef(null);
    useEffect(() => {
        if (inEditMode.status) {
          notesInputRef.current.focus();
        }        
    }, [notes, inEditMode.status]);
    useEffect(() => {
        if (inEditMode.status) {
          quantityInputRef.current.focus();
        }        
    }, [quantity, inEditMode.status]);
    useEffect(() => {
        if (inEditMode.status) {
          rateInputRef.current.focus();
        }
    }, [rate, inEditMode])

    const GetHousingPayments = useCallback(() => {
      const body = JSON.stringify({
          "EventRegistrationId" : theGuest.eventRegistrationId,
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });

        const url = SERVICE_URL + "/getRegistrationPayments";
      fetch(url, {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 406) {
              //setResponseMsg("No Events found.");
            } else {
              //setResponseMsg("There was a problem with the request for Event data.");
            }
            
            return null;
          }
      })
      .then((responseJson) => {
          //if (responseJson !== null) setResponseMsg("");
          setPaymentData(responseJson);
      });
    }, [theGuest, userInfo.userName]);

    const GetHousingCharges = useCallback(() => {
        const body = JSON.stringify({
            "EventRegistrationId" : theGuest.eventRegistrationId,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/getRegistrationCharges";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 406) {
                //setResponseMsg("No Events found.");
              } else {
                //setResponseMsg("There was a problem with the request for Event data.");
              }
              
              return null;
            }
        })
        .then((responseJson) => {
            //if (responseJson !== null) setResponseMsg("");Json);
            setChargeData(responseJson);
            GetHousingPayments();
        });
    }, [theGuest, GetHousingPayments, userInfo.userName]);

    useEffect(() => {
      if (theGuest !== null) {
        GetHousingCharges(); 
      }        
    }, [theGuest, GetHousingCharges])

    let chargeTable = <div />;
    if (chargeData !== null) {
        chargeTable = <table>
        <thead>
        <tr>
            <th>Charge Date</th>
            <th>Billable Item</th>
            <th>Quantity</th>
            <th>Rate</th>
            <th>Notes</th>
            <th></th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        {
            chargeData.map((item) => (
                <tr key={item.chargeId}>
                    <td>{new Date(item.timeStamp).toLocaleDateString('en-US')}</td>
                    <td>{item.billableItemName}</td>
                    <td>
                      {
                          inEditMode.status && inEditMode.rowKey === item.chargeId ? (
                              <input type="number" min="0" step="1" 
                                  value={quantity} ref={quantityInputRef}
                                  onChange={(event) => setQuantity(event.target.value)}
                              />
                          ) : (
                              item.quantity
                          )
                      }
                    </td>
                    <td>
                      {
                          inEditMode.status && inEditMode.rowKey === item.chargeId ? (
                              <input type="number" 
                                  value={rate} ref={rateInputRef}
                                  onChange={(event) => setRate(event.target.value)}
                              />
                          ) : (
                              item.rate.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                          )
                      }
                    </td>
                    <td>
                        {
                            inEditMode.status && inEditMode.rowKey === item.chargeId ? (
                                <input value={notes} ref={notesInputRef}
                                       onChange={(event) => setNotes(event.target.value)}
                                />
                            ) : (
                                item.notes
                            )
                        }
                    </td>
                    {!item.batched && <td> {
                            inEditMode.status && inEditMode.rowKey === item.chargeId ? (
                                <React.Fragment>
                                    <button
                                        className={"btn-success"}
                                        onClick={() => onSave({id: item.chargeId, quantity: quantity, 
                                            rate: rate, notes: notes})}
                                    >
                                        Save
                                    </button>

                                    <button
                                        className={"btn-secondary"}
                                        style={{marginLeft: 8}}
                                        onClick={() => onCancel()}
                                    >
                                        Cancel
                                    </button>
                                </React.Fragment>
                            ) : (
                                <button
                                    className={"btn-primary"}
                                    onClick={() => onEdit({id: item.chargeId, quantity: item.quantity, rate: item.rate, currentNotes: item.notes})}
                                >
                                    Edit
                                </button>                                
                            )
                        }
                    </td>}
                    {item.batched && <td><div /></td>}
                    {!item.batched && 
                    <td>
                    <button
                          className={"btn-primary"}
                          onClick={() => onDelete({id: item.chargeId, type: "charge"})}
                      >
                          Delete
                      </button>
                    </td>}
                    {item.batched && <td><div /></td>}
                </tr>
            ))
        }
        </tbody>
    </table>
    }

    let paymentTable = <div />;
    if (paymentData !== null) {
        paymentTable = <table>
        <thead>
        <tr>
            <th>Payment Date</th>
            <th>Billable Item</th>
            <th>Payment Method</th>
            <th>Amount</th>
            <th>Notes</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        {
            paymentData.map((item) => (
                <tr key={item.paymentId}>
                    <td>{new Date(item.timeStamp).toLocaleDateString('en-US')}</td>
                    <td>{item.billableItemName}</td>
                    <td>{item.paymentMethod}</td>
                    <td>{item.paymentAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                    <td>{item.notes}</td>
                    {!item.batched && <td> <button
                          className={"btn-primary"}
                          onClick={() => onDelete({id: item.paymentId, type: "payment"})}
                      >
                          Delete
                      </button>
                    </td>}
                    {item.batched && <td><div /></td>}        
                    
                </tr>
            ))
        }
        </tbody>
    </table>
    }
    if (paymentData !== null && chargeData !== null) {
      return (
        <div>
          
          <div className="checkin-content-subtable">
            <h3>Charges</h3>
            <Styles>
              {chargeTable}
            </Styles> 
          </div> 
          <div className="checkin-content-subtable">
            <h3>Payments</h3>
            <Styles>
              {paymentTable}
            </Styles> 
          </div>           
        </div>
      );
    } else {
      return (
      <div>
        Loading Charge and Payment Data...
      </div>
      );
    }
    
}