import React, { useState } from 'react';
import { Table, BillStyles, SERVICE_URL, MODE } from '../controls/Shared';
import logo from '../SICLogoText.png';

export function Bill(props) {
    const { eventRegistration, billSummaryColumns, billSummary, totalCharge, totalPayment, userInfo } = props;

    const [header, setHeader] = useState("");
    const [footer, setFooter] = useState("");
    const [gettingDetails, setGettingDetails] = useState(false);

    let eventNames = [];
    billSummary.forEach((billItem) => {
        if (!eventNames.includes(billItem.eventName)) {
            eventNames.push(billItem.eventName);
        }
    }); 
    const allNames = [...new Set(billSummary.map(q => q.fullName))];
    const allBills = [];
    eventNames.forEach((eventName) =>  {
        const subBillDetails = allNames.map(function (name) {
            const nameRows = billSummary.filter(function (bsum) {
                return bsum.fullName === name && bsum.eventName === eventName;
            });
            const billName = name + " - " + nameRows[0].eventName;
            const bsTable = <Table columns={billSummaryColumns} data={nameRows} hiddenFields={[]} />;
            return <div><span class="confereeBillChargeName"><b><i>{billName}</i></b></span><div>{bsTable}</div><br /></div>
        });
        allBills.push(subBillDetails);
    });
    const billDetails = allBills.map((billDeet) => { return billDeet;});
    var chg = totalCharge;
    var pmt = totalPayment;
    var bal = chg - pmt;
    const ccFee = bal * .03;
            const balWithFee = bal + ccFee;
            const creditCardFeeSection = eventRegistration.chargeCreditCardFee === null || eventRegistration.chargeCreditCardFee === undefined 
                    ? <div>
                        <p><b><span class="confereeBillPaymentTotal">Credit Card Fees (optional): {ccFee.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span></b></p> 
                        <p><b><span class="confereeBillGrandTotal">Balance including Credit Card Fees: {balWithFee.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span></b></p>
                        <br />
                        <br />
                        <br />
                      </div>
                    : <div />;

    function GetPrintDetails() {
        const url = SERVICE_URL + "/getPrintDetails";
        setGettingDetails(true);
        const body = JSON.stringify({
        "TestMode" : MODE === "TEST",
        "Requestor" : userInfo.userName
        });
        
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
            'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
            return response.json();
            } else {
            if (response.status === 406) {
                alert("No Print Details found.");
            } else {
                alert("There was a problem with the request for Guest Info data.");
            }            
            return null;
            }
        })
        .then((responseJson) => {
            setHeader(responseJson.printHeader);
            setFooter(responseJson.printFooter);
        }); 
    }
    
    if (header === "" && footer === "" && !gettingDetails) {
        GetPrintDetails();
    }

    return (
        <div className="confereeBill">
            <img id="logo" src={logo} alt="Logo goes Here"/>
            <br />        
            <div className="confereeBillHeader" dangerouslySetInnerHTML={{__html: header}}>
            </div>
            <div>
                <div className="confereeBillEventHeading">
                    <div style={{fontWeight: "bold"}}>{eventNames.join(";")}</div>
                    <span class="confereeBillPartyMembers">{allNames.join(";")}</span>
                </div>
            </div>
            <br />
            <BillStyles>
                {billDetails}
            </BillStyles>
            <br />
            <p><span class="confereeBillChargeTotal">Total Charges: {chg.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span></p>
            <p><span class="confereeBillPaymentTotal">Total Payments: {pmt.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span></p>
            <p><span class="confereeBillGrandTotal">Balance: {bal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span></p>
            {creditCardFeeSection}
            <br />
            <br />
            <br />
            <div id="BillFooter" class="confereeBillFooter" dangerouslySetInnerHTML={{__html: footer}} />
        </div>

    )

}