import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MODE, SERVICE_URL, Styles, PrintStyles, Table, DataYear } from '../../controls/Shared';
import Popup from '../../controls/Popup';
export default function Reports(props) {

    const { userInfo } = props;

    const [batchData, setBatchData] = useState(null);
    const [batchSummary, setBatchSummary] = useState(null);
    const [batchTransactionLogData, setBatchTransactionLogData] = useState(null);
    const [batchedReport, setBatchedReport] = useState(null);
    const [annualFundReport, setAnnualFundReport] = useState(null);
    const [responseMsg, setResponseMsg] = useState("No Data Found for Year");
    const [years, setYears] = useState([]);
    const currentYear = DataYear;
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState(<div />);
    //const [startDate, setStartDate] = useState(null);
    //const [endDate, setEndDate] = useState(null);
    const [reportLabel, setReportLabel] = useState("");
    const [weeklyBatches, setWeeklyBatches] = useState([]);

    const { current: deetColumns } = useRef(
        [
            {
                Header: "Timestamp",
                accessor: "batchTimeStamp",
                Cell: row => {
                    return(
                      <div>{new Date(row.value).toLocaleDateString('en-US')}</div>
                    )
                  }
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: row => {
                    return(
                      <div>{row.value.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}</div>
                    )
                  }
            },
            {
                Header: "Payment Method",
                accessor: "paymentMethod"
            },
            {
                Header: "Event Registration",
                accessor: "eventRegistration"
            }
        ]
    );
    
    const { current: summaryColumns } = useRef(
      [
        {
          Header: "Item",
          accessor: "item"
        },
        {
          Header: "Description",
          accessor: "description"
        },
        {
          Header: "GL_Credit",
          accessor: "gL_Credit"
        },
        {
          Header: "Total",
          accessor: "amount",
          Cell: row => {
            if (row.row.values.item === "") {
              return (null)
            } else {
              return(
                <div>{row.value.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}</div>
              )
            }
            
          }
        }
      ]
    );

    const { current: annualFundColumns } = useRef(
      [
        {
          Header: "Constituent Name",
          accessor: "constituentName"
        },
        {
          Header: "Fund Description",
          accessor: "fundDescription"
        },
        {
          Header: "Payment Date",
          accessor: "paymentDate"
        },
        {
          Header: "Amount",
          accessor: "amount",
          Cell: row => {
            if (row.row.values.item === "") {
              return (null)
            } else {
              return(
                <div>{row.value.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}</div>
              )
            }
            
          }
        }
      ]
    );

    function UpdateWeeklyBatches(e, id) {
      let batches = JSON.parse(JSON.stringify(weeklyBatches));
      if (e.target.checked) {
        batches.push(id);
      } else {
        const index = batches.indexOf(id);
        batches.pop(index, 1);
      }
      setWeeklyBatches(batches);
    }

    function ClosePopup() {
        setShowPopup(false);
    }

    function ShowDetails(row) {
        
        const selectedDetails = batchData.filter((batch) => {
            return batch.batchId === row;
        })

        const detailContent = (<PrintStyles><Table className="table" columns={deetColumns} data={selectedDetails} hiddenFields={[]}/></PrintStyles>);

        setPopupContent(<div>{detailContent}</div>);
        setShowPopup(true);
    }

    function ShowLog(row) {
      setReportLabel("");
      GetTransactionLog(row);  
      
    }

    useEffect(() => {
      if (batchTransactionLogData !== null) {
          const logColumns = Object.keys(batchTransactionLogData[0]).map((key, id)=>{
              return {
                Header: key,
                accessor: key
              }
            })
          const logTable = (<PrintStyles><Table className="table" columns={logColumns} data={batchTransactionLogData} hiddenFields={[]}/></PrintStyles>);
          setPopupContent(logTable);
          setShowPopup(true);
      }

    }, [batchTransactionLogData])

    function ShowSummary(row) {
      setReportLabel("Daily Front Desk Batch - Shift Note: " + row.shiftNote);
      GetBatchedReport(row.batchId, "Summary");
    }

    function GetWeeklyReport() {
      if (weeklyBatches.length === 0) {
        alert("Please select the batches for the Week below!");
      } else {
        setReportLabel("Weekly Front Desk Batch Summary");
        GetBatchedReport(-1, "Weekly");
      }      
    }

    useEffect(() => {
      if (batchedReport !== null) {
        const summaryTable = (<div><div><label>{reportLabel}</label></div><div><PrintStyles><Table className="table" columns={summaryColumns} data={batchedReport} hiddenFields={[]}/></PrintStyles></div></div>);
        setPopupContent(summaryTable);
        setShowPopup(true);
      }
    }, [batchedReport, summaryColumns, reportLabel]);

    useEffect(() => {
      if (annualFundReport !== null) {
        if (annualFundReport.some(x => x.category === "Fund")) {
          const summaryTable = (<div><div><label>{reportLabel}</label></div><div><PrintStyles><Table className="table" columns={annualFundColumns} data={annualFundReport} hiddenFields={[]}/></PrintStyles></div></div>);
          setPopupContent(summaryTable);
        } else {
          setPopupContent("No donations to report.");
        }        
        setShowPopup(true);
      } 
    }, [annualFundReport, annualFundColumns, reportLabel]);

    function ShowFinancial(row) {
      setReportLabel("");
      GetBatchedReport(row, "Financial");
    }
    
    const getBatched = useCallback(() => {
      const url = SERVICE_URL + "/getBatches";
  
      const body = JSON.stringify({
        "BatchYear": selectedYear,
        "TestMode" : MODE === "TEST",
        "Requestor" : userInfo.userName
      });
      
      fetch(url, {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 406) {
              setResponseMsg("No Batch Data found.");
            } else {
              setResponseMsg("There was a problem with the request for Batch data.");
            }
            
            return null;
          }
      })
      .then((responseJson) => {
          if (responseJson !== null) setResponseMsg("");
          setBatchData(responseJson);          
          if (responseJson !== null) {
            let summary = [];
            responseJson.forEach((batch) => {
              let sumBatch = {};
              sumBatch.batchId = batch.batchId;
              sumBatch.batchTimeStamp = batch.batchTimeStamp;
              sumBatch.longNote = batch.longNote;
              sumBatch.shiftNote = batch.shiftNote;
              const found = summary.some(function (item) {
                  return  item.batchId === sumBatch.batchId;
              });
              if (!found) {
                  summary.push(sumBatch);
              }
            });
            setBatchSummary(summary);
          }          
      });  
    }, [selectedYear, userInfo.userName]);

    function GetTransactionLog(batchId) {
        const url = SERVICE_URL + "/getBatchTransactionLog";
    
        const body = JSON.stringify({
          "BatchId": batchId,
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });
        
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 406) {
                setResponseMsg("No Batch Data found.");
              } else {
                setResponseMsg("There was a problem with the request for Batch data.");
              }
              
              return null;
            }
        })
        .then((responseJson) => {
            if (responseJson !== null) setResponseMsg("");
            setBatchTransactionLogData(responseJson);
        });  
    }

    const GetAnnualFundReport = useCallback(() => {
      if (weeklyBatches.length === 0) {
        alert("Please select the batches for the Week below!");
      } else {
        setReportLabel("Donation Report");
        const url = SERVICE_URL + "/getAnnualFundReport";

        const body = JSON.stringify({
          "Batches" : weeklyBatches,
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });

        fetch(url, {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 406) {
              setAnnualFundReport({});
              setResponseMsg("No Batch Data found.");
            } else {
              setResponseMsg("There was a problem with the request for Batch data.");
            }            
            return null;
          }
      })
      .then((responseJson) => {
          setResponseMsg("");
          setAnnualFundReport(responseJson);           
      });  
      }
    },[userInfo.userName, weeklyBatches]);

    function GetBatchedReport(batchId, type) {

      let url = "";
      let body = {};
      if (type === "Weekly") {
        url = SERVICE_URL + "/getBatchedWeeklyReport";
  
        body = JSON.stringify({
          "Batches" : weeklyBatches,
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        })
      } else {
        url = SERVICE_URL + "/getBatchedReport";
  
        body = JSON.stringify({
          "BatchId": batchId,
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });        
      }
      
      fetch(url, {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 406) {
              setResponseMsg("No Batch Data found.");
            } else {
              setResponseMsg("There was a problem with the request for Batch data.");
            }
            
            return null;
          }
      })
      .then((responseJson) => {
          if (responseJson !== null)  {
            setResponseMsg("");
            if (type === "Summary") {
              setBatchedReport(responseJson);
            } else {
              //Save this for possible changes to the report for the financial output
              //let financialResponse = [];
              //responseJson.forEach((entry) => {
              //  if (entry.category === "Fund") {
              //    financialResponse.push(entry);
              //  }                
              //});
              //setBatchedReport(financialResponse);
              setBatchedReport(responseJson);
            }
            
          }  
          
      });  
  }
    
    useEffect(() => {
        if (batchData === null) {
            getBatched();
        }
        if (years.length === 0) {        
            const filledYears = [];
            for (var i = currentYear; i >= currentYear - 10; i--) {
              filledYears.push(i);
            }
            setYears(filledYears);
        }  
    }, [batchData, years.length, currentYear, getBatched]);

    useEffect(() => {
        getBatched();
    }, [selectedYear, getBatched]);

   const yearSelect = years === undefined ? <div /> : 
    <select onChange={(event) => setSelectedYear(parseInt(event.target.value))}> {
        years.map((year) => {
        return <option key={year} value={year}>{year}</option>
        })
        }
    </select>;

    let batchTable = <div />;
    let userMsg = <div />;
    if (batchSummary !== null) {
        batchTable = //<Table className="table" columns={columns} data={batchData} hiddenFields={[]}/>; 
        <table>
        <thead>
        <tr>
            <th></th>
            <th>Batch TimeStamp</th>
            <th>Shift Note</th>
            <th>Long Note</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        {
            batchSummary.map((item) => (
                <tr key={item.batchId}>
                    <td><input type="checkbox" onClick={(e) => UpdateWeeklyBatches(e, item.batchId)}/></td>
                    <td>{new Date(item.batchTimeStamp).toLocaleDateString('en-US')}</td>
                    <td>{item.shiftNote}</td>
                    <td>{item.longNote}</td>
                    <td>
                        {   
                            <button  onClick={() => ShowDetails(item.batchId)}>
                                Show Details
                            </button>
                        }
                    </td>
                    <td>
                        {
                            <button  onClick={() => ShowLog(item.batchId)}>
                                Show Log
                            </button>
                        }
                    </td>
                    <td>
                        {
                            <button onClick={() => ShowSummary(item)}>
                                Show Daily Summary
                            </button>
                        }
                    </td>
                    <td>
                        {
                            <button onClick={() => ShowFinancial(item.batchId)}>
                                Show Financial Report
                            </button>
                        }
                    </td>
                </tr>
            ))
        }
        </tbody>
    </table>
    } else {
        userMsg = <div className="warning">{responseMsg}</div>;
    }
    const popup = showPopup ? <Popup closePopup={ClosePopup} enablePrint={true}>{popupContent}</Popup> : undefined;
    return (
        <div>
            <label>
                Batch Year
                {yearSelect}
            </label>
            <button className='button' onClick={() => getBatched()}>Refresh</button> 
            <div>
              <button className='button' onClick={() => GetWeeklyReport()}>Weekly Summary Report</button>
              <button className='button' onClick={() => GetAnnualFundReport()}>Donation Report</button>
            </div>
            
            <div className="checkin-content-tabbed">
                {userMsg}
                <Styles>
                    {batchTable}
                </Styles>
            </div>
            {popup}
        </div>

    )
}