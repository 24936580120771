import React, { useState, useCallback, useEffect } from 'react';
import { SERVICE_URL, MODE, DataYear } from '../../controls/Shared';

export default function EventManagement(props) {

    const { userInfo } = props;

    const [events, setEvents] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null); 
    const [selectedGuest, setSelectedGuest] = useState(null); 
    const [guestResponseMsg, setGuestResponseMsg] = useState("");
    const [showGuestList, setShowGuestList] = useState(false);
    const [actionType, setActionType] = useState("");
    const [guestform, setGuestForm] = useState(<div />);
    const [guestEditData, setGuestEditData] = useState(null);

    const [items, setItems] = useState(null);
    const currentYear = DataYear;      


    const GetEvents = useCallback(() => {
        const body = JSON.stringify({
            "NoTemp" : true,
            "Year" : currentYear,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/getEvents";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return null;
            }
        })
        .then((responseJson) => {
            //if (responseJson !== null) setResponseMsg("");
            //don't let this fail.
            if (responseJson !== null) {
                responseJson.unshift("");
            }
            setEvents(responseJson);
        });
    }, [currentYear, userInfo.userName]);

    const GetAllGuests = useCallback((eventId) => {
        setItems(null);
        setGuestResponseMsg("Getting Guests...");
        const body = JSON.stringify({
            "EventId" : eventId,
            "RequestType" : "EventManagement",
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/getEventRegistrations";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 406) {
                setGuestResponseMsg("No Guests found.");
              } else {
                alert("Something went wrong. See Event Logs for details");
              }              
              return null;
            }
        })
        .then((responseJson) => {
            setGuestResponseMsg("");           
            setItems(responseJson);
        });
    }, [userInfo.userName]);

    const AddNewGuest = useCallback((eventId, guestObject) => {
        const body = JSON.stringify({
            "EventId" : eventId,
            "GuestObject": guestObject,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/addNewGuestToEvent";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
                alert("Guest has been added to Event");
            } else {
                alert("Something went wrong. See Event Logs for details");
            }
        })
        .then(() => {
            CancelAction();
        });
    }, [userInfo.userName]);

    const UpdateEventRegistration = useCallback((guestObject) => {
        const body = JSON.stringify({
            "GuestObject": guestObject,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/updateEventRegistration";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
                alert("Event Registration has been updated!");
            } else {
                alert("Something went wrong. See Event Logs for details");
            }
        })
        .then(() => {
            CancelAction();
        });
    }, [userInfo.userName]);

    const DeleteEventRegistration = useCallback((eventRegistration) => {
        const body = JSON.stringify({
            "EventRegistrationId" : eventRegistration.eventRegistrationId,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
            });

            const url = SERVICE_URL + "/deleteEventRegistration";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
            if (response.ok) {
                alert("Event Registration is now marked as Deleted.");
            } else {
                alert("Something went wrong. See Event Logs for details");
            }
        })
        .then(() => {
            CancelAction();
        });   
    }, [userInfo.userName]);

    const updateContent = useCallback((e, name) => {
        let updated = {};
        for (var key in guestEditData) {
            if (key !== name) {
                updated[key] = guestEditData[key];
            } else {                    
                updated[key] = e.target.value;               
            }          
        }
        setGuestEditData(updated);
    }, [guestEditData]);

    function UpdateGuestRecord(type, eventReg, editedGuestData) {
        if (type === "Remove") {
            const guestMsg = eventReg.constituentType === "Registrant" && eventReg.guestCount > 0 ? "This guest has other guests in their party. Please clear party before removing this guest" : "";
            const chgPmtMsg = eventReg.chargeCount > 0 || eventReg.paymentCount > 0 ? "This guest has charges and/or payments. Please delete these before removing this guest." : "";
            if (guestMsg !== "" || chgPmtMsg !== "") {
                alert(guestMsg + " " + chgPmtMsg);
            } else {
                DeleteEventRegistration(eventReg);
            }            
        } else {
            UpdateEventRegistration(editedGuestData);
        }
    }

    function CancelAction() {
        setGuestForm(<div />);
        setGuestEditData(null);
        setShowGuestList(false);
        setSelectedGuest(null);
    }

    function getEventRegistrations(type) {
        if (selectedEvent === null) {
            alert("You have not selected an event!");
            setShowGuestList(false);
            setActionType("");
        } else {
            GetAllGuests(selectedEvent);
            setShowGuestList(true);  
            setActionType(type);         
        }
    }

    function CreateEditGuestData(reg) {
        if (!reg || reg === null) {
            const fullEvent = events.find(evt => evt.eventId === selectedEvent);
            if (fullEvent === null) {
                alert("Cannot find selected event!");
            } else {
                if (guestEditData === null) setGuestEditData({ "ConstituentId": 0, "FirstName": "", "LastName":"", "ArrivalDate": fullEvent.startDate, "DepartureDate": fullEvent.endDate, "BirthDate" : ""});
                setActionType("Add");
            }           
        } else {
            setGuestEditData(reg);
        }       
    }

    const toggleComplete = (eventReg) => {
        setSelectedGuest(eventReg);
        CreateEditGuestData(eventReg);
    };
    
    const eventSelect = events === undefined || events === null ? <div/> :
      <select width="75px" onChange={(event) => setSelectedEvent(event.target.value === "" ? null : parseInt(event.target.value))}> {
        events.map((event) => {
          return <option key={event.eventId} value={event.eventId}>{event.eventName}</option>
          })
        }
      </select>; 
    
    const regSelector = !showGuestList ? <div /> : <div className="boxedContent">                    
                            <div className='item-list'>
                                {items !== null ? (items.map((item) => (
                                    <div className='item-container'>
                                        <div className='item-name' onClick={() => toggleComplete(item)}>
                                        {selectedGuest !== null && item.eventRegistrationId=== selectedGuest.eventRegistrationId && item.fullName === selectedGuest.fullName ? (
                                            <span className='highlighted-unbalanced'>{item.fullName}</span>
                                            ) : (       
                                            <span>{item.fullName}</span>
                                            )}
                                        </div>
                                    </div>
                                ))) : <div>{guestResponseMsg}</div>}
                            </div>                                             
                        </div>     
      
    const actionButtons = selectedEvent === null || (selectedEvent !== null && (showGuestList || guestEditData !== null)) ? <div/> 
                : <div><div><b>Select an Action</b></div>                    
                    <button className='button' onClick={() => getEventRegistrations("Remove")}>Remove Guest</button>
                </div>

    const confirmButtons = actionType === "" || (actionType !== "Add" && selectedGuest === null) ? <div /> : actionType === "Add" ? <div><button className='button' onClick={() => AddNewGuest(selectedEvent, guestEditData)}>Add</button><button className='button' onClick={() => CancelAction()}>Cancel</button></div> 
            : <div><button className='button' onClick={() => UpdateGuestRecord(actionType, selectedGuest, guestEditData)}>{actionType}</button><button className='button' onClick={() => CancelAction()}>Cancel</button></div>;                                           
    
    useEffect(() => {
        CancelAction();
    }, [selectedEvent])

    useEffect(() => {
        if (guestEditData !== null && actionType !== "Remove") {
            let form = <div />;
            let fields = [];
            if (!guestEditData.BirthDateTime) {
                guestEditData.BirthDateTime = "";
            }
            for (const prop in guestEditData) {
                if (prop !== "chargeCount" && prop !== "paymentCount") {
                    let field = <div />;
                    field = field = <div>{prop} <input id={prop} type="text" value={guestEditData[prop]} onChange={(e) => updateContent(e, prop)}/></div>; 
                    fields.push(field);
                }                
            }
            
            form = <div>{fields}</div>
            setGuestForm(form);
        }        
    },[guestEditData, updateContent, actionType]);

    return (
        <div style={{height: '40vh'}}>
            <b>Select an Event</b>
            <br />Note: If the event is not listed, ensure that the EventType and RateSchedule have been set (ie: not "Temporary") under Content Management!
            <div className="sideBySide">
                <div style={{width: '30%',height: '40vh'} }> 
                    {eventSelect}<button className='button' onClick={GetEvents}>Refresh Events</button>               
                    {actionButtons} 
                    {regSelector}                
                </div>    
                <div style={{width: '40%'} }>
                    {confirmButtons}
                    <div style={{overflowY: 'scroll', scrollBehavior: 'auto', height: '30vh'}}>
                        {guestform}
                    </div>
                </div>
            </div>
        </div>
    );
    
}


