import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MODE, ContentStyles, Table, SERVICE_URL, DataYear } from '../controls/Shared';
import { ChargesAndPayments } from './OccupancyDisplayPages/ChargesAndPayments';
import Popup from '../controls/Popup';

export default function GuestInfo(props) {

    const { userInfo } = props;
  
    const [years, setYears] = useState([]);
    const currentYear = DataYear;
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [guestName, setGuestName] = useState("");
    const [guestData, setGuestData] = useState(null);
    const [responseMsg, setResponseMsg] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState(<div />);  

    function ClosePopup() {
      setPopupContent(null);
      setShowPopup(false);
    } 

    const { current: columns } = useRef([
            {
                Header: "",
                accessor: "guestId",
                Cell: row => {
                  return(
                    <button onClick={() => showChargesAndPayments(row.value)}>Charges and Payments</button>
                  )
                }
            },
            {
                Header: "First Name",
                accessor: "firstName"
            },
            {
                Header: "Last Name",
                accessor: "lastName"
            },
            {
                Header: "Event Name",
                accessor: "eventName"
            },
            {
                Header: "Room",
                accessor: "roomName"
            },
            {
                Header: "Room Type",
                accessor: "roomType"
            },
            {
                Header: "Room Type Preference One",
                accessor: "roomTypePreferenceOne"
            },
            {
                Header: "Preferred Arrival Date Time",
                accessor: "preferredArrivalDateTime",
                Cell: row => {
                  return(
                    <div>{new Date(row.value).toLocaleDateString('en-US')}</div>
                  )
                }
            },
            {
                Header: "Preferred Departure Date Time",
                accessor: "preferredDepartureDateTime",
                Cell: row => {
                  return(
                    <div>{new Date(row.value).toLocaleDateString('en-US')}</div>
                  )
                }
            },
            {
                Header: "Preferred Meal Type",
                accessor: "mealType"
            },
            {
              Header: "Phone Number",
              accessor: "phoneNumber"
            },
            {
              Header: "Email Address",
              accessor: "emailAddress"
            },
            {
              Header: "Age",
              accessor: "age"
            }
        ]
      ); 

  function showChargesAndPayments(guestId) {
      let theGuest = {};
      theGuest.eventRegistrationId = guestId;
      setPopupContent(<ChargesAndPayments SelectedGuest={theGuest} userInfo={userInfo}/>);
      setShowPopup(true);
  }
      

  const getGuestInfo = useCallback(() => {
    const url = SERVICE_URL + "/getGuestInfo";

    const body = JSON.stringify({
      "EventYear" : selectedYear,
      "GuestName" : guestName,
      "TestMode" : MODE === "TEST",
      "Requestor" : userInfo.userName
    });
    
    fetch(url, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 406) {
            setResponseMsg("No Guests found.");
          } else {
            setResponseMsg("There was a problem with the request for Guest Info data.");
          }
          
          return null;
        }
    })
    .then((responseJson) => {
        if (responseJson !== null) setResponseMsg("");
        setGuestData(responseJson);
    });  
  }, [selectedYear, guestName, userInfo.userName]);

    useEffect(() => {
      if (years.length === 0) {        
        const filledYears = [];
        for (var i = currentYear; i >= currentYear - 10; i--) {
          filledYears.push(i);
        }
        setYears(filledYears);
      }      
    }, [years.length, currentYear]);    

    const yearSelect = years === undefined ? <div /> : 
    <select onChange={(event) => setSelectedYear(parseInt(event.target.value))}> {
        years.map((year) => {
        return <option key={year} value={year}>{year}</option>
        })
        }
    </select>;

    let guestTable = <div />;
    let userMsg = <div />;
    if (guestData !== null) {
        guestTable = <Table className="table" columns={columns} data={guestData} hiddenFields={[]}/>; 
    } else {
        userMsg = <div className="warning">{responseMsg}</div>;
    }

    const handleKeypress = e => {
      //it triggers by pressing the enter key
      if (e.charCode === 13) {
        getGuestInfo();
      }
    }; 
    

    const popup = showPopup ? <Popup closePopup={ClosePopup} enablePrint={false}>{popupContent}</Popup> : undefined;

    return (
        <div>
            <label>Event Year</label>
            {yearSelect}
            <label>Guest Name</label>
            <input type="text" value={guestName} onChange={(event) => setGuestName(event.target.value)} onKeyPress={handleKeypress}/>
            <button className='button' onClick={() => getGuestInfo()}>Lookup Guests</button>
            <div>
                {userMsg}  
                <div className="guest-info">           
                  <ContentStyles>
                      {guestTable}
                  </ContentStyles>
                </div> 
            </div>
            {popup}
        </div>
    )
}