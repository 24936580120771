import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MODE, SERVICE_URL, Styles, Table, SelectableTable } from '../../controls/Shared';

export default function BatchOpenDrawer(props) {

    const { userInfo } = props;
  
    const [batchData, setBatchData] = useState(null);
    const [batchTotalData, setBatchTotalData] = useState(null);
    const [responseMsg, setResponseMsg] = useState("");
    const [shiftNote, setShiftNote] = useState("");
    const [longNote, setLongNote] = useState("");
    const [confirmedBatches, setConfirmedBatches] = useState([]);
    const [loading, setLoading] = useState(false);

    const { current: batchTableColumns } = useRef(
        [
            {
                Header: "Payment ID",
                accessor: "paymentId"
            },
            {
                Header: "Batch Date",
                accessor: "batchTimeStamp",
                Cell: row => {
                  return(
                    <div>{new Date(row.value).toLocaleString('en-US')}</div>
                  )
                }
            },
            {
                Header: "Category",
                accessor: "category"
            },
            {
                Header: "Item",
                accessor: "item"
            },
            {
                Header: "Amount",
                accessor: "batchAmount",
                Cell: row => {
                  return(
                    <div>{row.value.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}</div>
                  )
                }
            },
            {
              Header: "Total Payment",
              accessor: "batchTotalAmount",
              Cell: row => {
                return(
                  <div>{row.value.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}</div>
                )
              }
            },
            {
                Header: "Payment Method",
                accessor: "batchPaymentMethod"
            },
            {
                Header: "Registrant",
                accessor: "batchRegistrant"
            },
            {
                Header: "Event",
                accessor: "batchEvent"
            },
            {
                Header: "Year",
                accessor: "batchYear"
            }

        ]
      );

      const { current: batchTotalColumns } = useRef(
          [
            {
                Header: "Method",
                accessor: "batchPaymentMethod"
            },
            {
                Header: "Total",
                accessor: "batchAmount",
                Cell: row => {
                  return(
                    <div>{row.value.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}</div>
                  )
                }
            }
          ]
      )

      const getTotals = useCallback(() => {
        const url = SERVICE_URL + "/getBatchedTotals";
    
        const body = JSON.stringify({
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });
        
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 406) {
                setResponseMsg("No Batch Totals found.");
              } else {
                setResponseMsg("There was a problem with the request for Batch Totals.");
              }
              
              return null;
            }
        })
        .then((responseJson) => {
            if (responseJson !== null) setResponseMsg("");
            setBatchTotalData(responseJson);
        });  
      },[userInfo.userName]);

      const getBatched = useCallback(() => {
        setResponseMsg("Getting Batches...");
        const url = SERVICE_URL + "/getBatched";
    
        const body = JSON.stringify({
          "Unpaid" : true,
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });
        
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 406) {
                setResponseMsg("No Batch Data found.");
              } else {
                setResponseMsg("There was a problem with the request for Batch data.");
              }
              
              return null;
            }
        })
        .then((responseJson) => {
            if (responseJson !== null) setResponseMsg("");
            setBatchData(responseJson);
            getTotals();
        });  
      }, [getTotals, userInfo.userName]);

      

      useEffect(() => {
        if (batchData === null) {
            getBatched();
        }
      }, [batchData, getBatched]); 

    function batchDrawer() {
      setResponseMsg("Batching Drawer...");
      if (shiftNote === "") {
        alert("Please ensure Shift Note have values.")
      } else {
        const body = JSON.stringify({
          "Batches" : confirmedBatches,
          "BatchDate" : new Date(),
          "ShiftNote" : shiftNote,
          "LongNote" : longNote,
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });
          const url = SERVICE_URL + "/batchDrawer";
          
              fetch(url, {
                  method: 'POST',
                  body: body,
                  headers: {
                      'Content-Type': 'application/json'
                  }
              }).then(response => {
                if (response.ok) {
                  return response.json();                  
                } else {
                  alert("Error when attempting to Batch the Drawer.")
                }
              }).then((responseJson) => {
                  getBatched();
                  //exportBatchReport(responseJson);
                  setShiftNote("");
                  setLongNote("");
                  setConfirmedBatches([]);
              });
      }
    }

    function GetOverPayments() {
      setLoading(true);
      let body = {};
      const reportLabel = "PartyOverpayments";
      body.reportType = "year";
      body.year = new Date().getFullYear();
      body.procName = "RPT_GetPartyOverpayments";
      body.TestMode = MODE === "TEST";
      body.Requestor = userInfo.userName;
      const url = SERVICE_URL + "/adminReport";
      fetch(url, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
              alert("There has been an issue getting the report. Please see the Event Logs for more detail.")
              setLoading(false);
            return null;
          }
      })
      .then((responseJson) => {
          const currDate = new Date();
          let fileName = reportLabel.replace(" ","") + " - " + currDate.toLocaleDateString("en-us");
          //if (responseJson !== null) setResponseMsg("");
          if (responseJson !== null && responseJson.length > 0) {
              exportReport(responseJson, fileName);
          }
          setLoading(false);
      });
    }

    function exportReport(json, name) {
      let csv = "";
      let accessors = [];
      let row = [];
      const data = json;
      const headers = Object.keys(json[0]).map((key, id)=>{
          return {
            Header: key,
            accessor: key
          }
        });
      const filename = name + ".csv";
      for (let i = 0; i < headers.length; i++) {
          row.push(headers[i].Header);
          accessors.push(headers[i].accessor);
      }
      csv = csv + (row.join(",") + "\n");
      for (let i = 0; i < data.length; i++) {
          let tRow = [];
          const dRow = data[i];
          for (let j = 0; j < accessors.length; j++) {
              const accessor = accessors[j];
              var val = "";
              if (accessor === "Arrival" || accessor === "Departure" || accessor === "BirthDate") 
              {
                  var dateValue = new Date(dRow[accessor]).toLocaleDateString('en-us');
                  val = '"' + dateValue + '"';
              } else {
                  val = '"' + dRow[accessor] + '"';
              }                
              tRow.push(val.replace(null, ''));
          }
          csv = csv + (tRow.join(",") + "\n");            
      }
      let csvFile;
      csvFile = new Blob([csv], {type: "text/csv;encoding:utf-8" });
      if (navigator.appVersion.toString().indexOf('.NET') > 0) {
          window.navigator.msSaveBlob(csvFile, filename);
      } else {
          let downloadLink;
          downloadLink = document.createElement("a");
          downloadLink.download = filename;
          downloadLink.href = window.URL.createObjectURL(csvFile);
          downloadLink.style.display = "none";
          document.body.appendChild(downloadLink);
          downloadLink.click();
      }
    }

    let batchTable = <div />;
    let batchTotalTable = <div />;
    let userMsg = <div />;
    const hiddenFields = ["paymentId"];
    if (batchData !== null && responseMsg === "") {
        batchTable = <SelectableTable className="table" columns={batchTableColumns} data={batchData} hiddenFields={hiddenFields}
          setSelection={setConfirmedBatches} confirmedBatches={confirmedBatches} />; 
        if (batchTotalData !== null) {
          batchTotalTable = <Table className="table" columns={batchTotalColumns} data={batchTotalData} hiddenFields={[]}/>;
        }
    } else {
        batchTable = <div />;
        userMsg = <div className="warning">{responseMsg}</div>;
    }

    return (
        <div>            
            <div className="checkin-content-tabbed">
              <label>
                Shift Note:
                <input type="text" onChange={e => setShiftNote(e.target.value)} value={shiftNote}/>
              </label>
              <label>
                Long Note:
                <input type="text" onChange={e => setLongNote(e.target.value)} value={longNote}/>
              </label>
              <button className='button' onClick={batchDrawer}>Batch Open Drawer</button>
              <div>
                <button className='button' onClick={getBatched}>Refresh</button> 
                <button className='button' onClick={GetOverPayments} disabled={loading}>Run Party Overpayment Report</button>
              </div>
                {userMsg}              
                <Styles>
                    {batchTable}
                </Styles>
                <Styles>
                  {batchTotalTable}
                </Styles>
            </div>
        </div>
        
    )
}