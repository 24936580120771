import React, { useState, useRef } from 'react';
import { MODE, SERVICE_URL } from '../../controls/Shared';
import { parse } from 'papaparse';

export default function Import(props) {

    const { userInfo } = props;

    const [eventFile, setEventFile] = useState("");
    const inputRef = useRef(null);
    const inputRegRef = useRef(null);
    const [eventRegFile, setEventRegFile] = useState("");
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [message, setMessage] = useState("");
    //const [events, setEvents] = useState([]);

    function clearFileEntry(url) {
        if (url.includes("Registrations")) {
            inputRegRef.current.value = null;
        } else {
            inputRef.current.value = null;
        }
    }

    const importData = (body, url) => {
        setMessage("Importing Now...");  
        setButtonEnabled(false);   
            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then(response => {
                    if (response.status !== 200) {
                        alert("An error has occurred with the import. Please see the Event Logs for more detail");
                        clearFileEntry(url);
                    } else {
                        alert("Import successful!");
                        clearFileEntry(url);
                    }
                    setButtonEnabled(true);
                    setMessage("");
                })
    }

    function processEventFile() {
        if (eventFile && eventFile !== null && eventFile !== "") {            
            parse(eventFile[0], {
                encoding: "UTF-8",
                complete: function(results) {
                    const body = JSON.stringify({
                        "EventFile" : results.data,
                        "TestMode" : MODE === "TEST"
                      });
                    const url = SERVICE_URL + "/importEvents"; 
                    importData(body, url);
                }
            });
        } else {
            alert("Cannot find a file to import.")
        }
    }

    function processEventRegFile() {
        if (eventRegFile && eventRegFile !== null && eventRegFile !== "") {
            parse(eventRegFile[0], {
                complete: function(results) {
                    const body = JSON.stringify({
                        "EventRegFile" : results.data,
                        "TestMode" : MODE === "TEST",
                        "Requestor" : userInfo.userName
                      });
                      const url = SERVICE_URL + "/importEventRegistrations"; 
                    importData(body, url);
                }
            });
        } else {
            alert("Cannot find a file to import.")
        }
    }

    return (
        <div className="checkin-content-tabbed">
            Make sure "Events" data is current before uploading "Event Registrations"...
            <div>
                Events:
                <div>
                    <input type="file" accept=".csv,.xls,.xlsx" disabled={!buttonEnabled} onChange={(e) => {setEventFile(e.target.files)}} ref={inputRef}/>
                    <button className='button' disabled={!buttonEnabled} onClick={processEventFile}>Import Event Data</button>
                </div>
                <div>
                    <input type="file" accept=".csv,.xls,.xlsx" disabled={!buttonEnabled} onChange={(e) => {setEventRegFile(e.target.files)}} ref={inputRegRef}/>
                    <button className='button' disabled={!buttonEnabled} onClick={processEventRegFile}>Import Event Registration Data</button>
                </div>
                <div style={{color: "red", fontSize: 'large'}}> {message} </div>
            </div>
        </div>
    )
}