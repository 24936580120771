import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { MODE, SERVICE_URL } from '../../controls/Shared';

export default function RateManagement(props) {

    const { userInfo, roomTypes } = props;

    const [rateSchedules, setRateSchedules] = useState(null);
    const [ageRanges, setAgeRanges] = useState(null);
    const [dateRanges, setDateRanges] = useState(null);
    const [selectedRateSchedule, setSelectedRateSchedule] = useState(null);
    const [nightlySchedules, setNightlySchedules] = useState(null);
    const [activeFlag, setActiveFlag] = useState(true);
    const [responseMsg, setResponseMsg] = useState("");
    const [rateScheduleName, setRateScheduleName] = useState("");
    const [rateScheduleDescription, setRateScheduleDescription] = useState("");
    const [nightCount, setNightCount] = useState(0);
    const [hallMonitoringAmount, setHallMonitoringAmount] = useState(0.00);
    const [activeValue, setActiveValue] = useState(true);
    const [contiguousNightAmount, setContiguousNightAmount] = useState(0.00);
    const [nightlySchedule, setNightlySchedule] = useState(-1);
    const [ageRates, setAgeRates] = useState(null);
    const [roomTypeRates, setRoomTypeRates] = useState(null);
    const [rightPanel, setRightPanel] = useState(<div />);
    const [newRateSchedule, setNewRateSchedule] = useState(false);

    const GetDateRanges = useCallback(() => {
      const body = JSON.stringify({
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });
      const url = SERVICE_URL + "/getDateRanges";
      fetch(url, {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 406) {
              alert("No Rate Schedules found.");
            } else {
              alert("There was a problem with the request for Event data.");
            }          
            return null;
          }
        })
        .then((responseJson) => {
            setDateRanges(responseJson);
        });
      }, [userInfo.userName]);

      const GetAgeRanges = useCallback(() => {
        const body = JSON.stringify({
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });
        const url = SERVICE_URL + "/getAgeRanges";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 406) {
                alert("No Rate Schedules found.");
              } else {
                alert("There was a problem with the request for Event data.");
              }          
              return null;
            }
        })
        .then((responseJson) => {
            setAgeRanges(responseJson);
            if (dateRanges === null) {
              GetDateRanges();          
            } 
        });
    }, [userInfo.userName, dateRanges, GetDateRanges]);

    const GetRateSchedules = useCallback(() => {
      const body = JSON.stringify({
          "ActiveOnly" : activeFlag,
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });
      const url = SERVICE_URL + "/getRateSchedules";
      fetch(url, {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 406) {
              setResponseMsg("No Rate Schedules found.");
            } else {
              setResponseMsg("There was a problem with the request for Event data.");
            }
            
            return null;
          }
      })
      .then((responseJson) => {
          setResponseMsg("");
          const activeOnly = responseJson.filter((rs) => {
            return rs.active === true;
          })
          setNightlySchedules(activeOnly);
          setRateSchedules(responseJson);
          setSelectedRateSchedule(responseJson[0].rateScheduleId);
          if (ageRanges === null) {
            GetAgeRanges();
          }
      });
    }, [activeFlag, userInfo.userName, ageRanges, GetAgeRanges]);    

    const GetRateScheduleAges = useCallback(() => {
        const body = JSON.stringify({
            "RateScheduleId" : selectedRateSchedule,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });
        const url = SERVICE_URL + "/getRateScheduleAges";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 406) {
                setResponseMsg("No Rate Schedule Ages found.");
              } else {
                setResponseMsg("There was a problem with the request for Event data.");
              }
              
              return null;
            }
        })
        .then((responseJson) => {
            setResponseMsg("");
            setAgeRates(responseJson);
        });
    }, [selectedRateSchedule, userInfo.userName]);

    const GetRateScheduleRoomTypes = useCallback(() => {
        const body = JSON.stringify({
            "RateScheduleId" : selectedRateSchedule,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });
        const url = SERVICE_URL + "/getRateScheduleRoomTypes";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 406) {
                setResponseMsg("No Rate Schedule Room Types found.");
              } else {
                setResponseMsg("There was a problem with the request for Event data.");
              }
              
              return null;
            }
        })
        .then((responseJson) => {
            setResponseMsg("");
            setRoomTypeRates(responseJson);
        });
    }, [selectedRateSchedule, userInfo.userName]);

    function SaveRateSchedule() {
      const body = JSON.stringify({
        "RateScheduleId" : selectedRateSchedule,
        "RateScheduleName" : rateScheduleName,
        "RateScheduleDescription" : rateScheduleDescription,
        "NightCount" : nightCount,
        "HallMonitoringAmount" : hallMonitoringAmount,
        "Active" : activeValue,
        "ContiguousNightAmount" : contiguousNightAmount,
        "NightlyScheduleId" : nightlySchedule,
        "TestMode" : MODE === "TEST",
        "Requestor" : userInfo.userName
      });
      const url = SERVICE_URL + "/saveRateSchedule";
      fetch(url, {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.ok) {
            //alert("Rate Schedule Updated!")
            setSelectedRateSchedule(null);
            GetRateSchedules();
          } else {
            alert("An error occurred with the update. Please see the Event Logs for more detail.");
          }
      });
    }

    function UpdateRates(type) {

      const body = JSON.stringify({
        "RateData" : type === "Age" ? ageRates : roomTypeRates,
        "RateType" : type,
        "TestMode" : MODE === "TEST",
        "Requestor" : userInfo.userName
      });
      const url = SERVICE_URL + "/updateRates";
      fetch(url, {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.ok) {
            alert("Rate Schedule Updated!")
          } else {
            alert("An error occurred with the update. Please see the Event Logs for more detail.");
          }
      });
   
    }  
    
    function AddRates(type, rates, rs, sd) {
      if (sd === undefined) {
        alert("we don't have a date range!");
      } else {
        rates.forEach(rate => {
          rate.rateScheduleId = rs.rateScheduleId;
          rate.nightCount = rs.nightCount;
          rate.dateRangeId = sd;
          rate.rateTypeId = type === "Age" ? rate.id : rate.roomTypeId;
        });
        if (rates.length > 0) {
          const body = JSON.stringify({
            "RateData" : rates,
            "RateType" : type,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });
          const url = SERVICE_URL + "/addRates";
          fetch(url, {
              method: 'POST',
              body: body,
              headers: {
                'Content-Type': 'application/json'
              }
            }).then((response) => {
              if (response.ok) {
                alert("New Rates Added!");
                GetRateScheduleRoomTypes();
                GetRateScheduleAges();
                setRightPanel(<div />);
              } else {
                alert("An error occurred with the update. Please see the Event Logs for more detail.");
              }
          });
        }   
      }
        
    }

    const updateAges = (e, id, type) => {
        var result = [...ageRates];
        result = result.map((x) => { 
          if (x.rateScheduleTypeId === id) 
            switch(type) {
              case "amount": 
                x.amount = parseFloat(e.target.value);
                break;
              case "daterange":
                x.dateRangeId = parseInt(e.target.value);
                break;
              default:
                alert("unknown type!");
            }
          return x;
        });
        setAgeRates(result);
        ShowAgeRates();
      };

    

    function ShowAgeRates() {
        let agePanel = <div />;
        let ageRateContent = <div />;
        if (ageRates !== null && dateRanges !== null) { 
            ageRateContent = ageRates.map((rate) => 
                <tr><td><label>Ages {rate.lowAge}-{rate.highAge} for {rate.nightCount} Night(s) in Range
                    <select width="75px" onChange={(event) => updateAges(event, rate.rateScheduleTypeId, "daterange")} value={rate.dateRangeId}>{
                      dateRanges.map((dr) => {
                        return <option key={dr.id} value={dr.id}>{new Date(dr.lowValue).toLocaleDateString('en-US')}-{new Date(dr.highValue).toLocaleDateString('en-US')}</option>
                      })
                    }
                    </select></label></td><td><input type="number" min="0" step=".01" value={rate.amount} onChange={(e) => updateAges(e, rate.rateScheduleTypeId, "amount")}/></td></tr>
            );
            agePanel = (<div id="ages">
                            {/* <label><b>{new Date(ageRates[0].lowDate).toLocaleDateString('en-US')}-{new Date(ageRates[0].highDate).toLocaleDateString('en-US')} </b> */}
                              <div><button className='button' onClick={() => UpdateRates("Age")}>Update Rates</button></div>
                                <table>{ageRateContent}</table>
                            {/* </label> */}
                            
                        </div>);
        } else {
          
          //see if they want to add a new age rate set and then show the table for entry
          if (window.confirm("There are not any Age Rates for this Rate Schedule. Would you like to add some?")) {
            if (dateRanges !== null) {
              let asd = dateRanges[0].id;
              const adateRangeSelect =   
                <select width="75px" onChange={(event) => asd = parseInt(event.target.value)}> {
                  dateRanges.map((dr) => {
                    return <option key={dr.id} value={dr.id}>{new Date(dr.lowValue).toLocaleDateString('en-US')}-{new Date(dr.highValue).toLocaleDateString('en-US')}</option>
                    })
                  }
                </select>;
              var rs = rateSchedules.find(rs => rs.rateScheduleId === selectedRateSchedule); 
              const json = JSON.stringify(ageRanges.sort(function(a, b) {
                return parseInt(a.lowValue) - parseInt(b.lowValue);
                      }));
              const ageRangeSet = JSON.parse(json);
              ageRateContent = ageRangeSet.map((range) => 
                  <tr><td><label>Ages {range.lowValue}-{range.highValue} for {rs.nightCount} Night(s) in selected Date Range</label></td><td><input type="number" min="0" step=".01" value={range.amount} onChange={(e) => range.amount = parseInt(e.target.value)}/></td></tr>
              );
              agePanel = (<div id="ages">
                              {adateRangeSelect}
                                <div><button className='button' onClick={() => AddRates("Age", ageRangeSet, rs, asd)}>Add Rates</button></div>
                                  <table>{ageRateContent}</table>                              
                          </div>);
            } else {
              alert("No Date Ranges have been found. Please check the Date Range table to ensure data is available.")
            }            
          } 
        }
        setRightPanel(agePanel);
    }

    const updateRoomTypes = (e, id, type) => {
      var result = [...roomTypeRates];
      result = result.map((x) => { 
        if (x.rateScheduleTypeId === id) 
            switch(type) {
              case "amount": 
                x.amount = parseFloat(e.target.value);
                break;
              case "daterange":
                x.dateRangeId = parseInt(e.target.value);
                break;
              default:
                alert("unknown type!");
            }
          return x;
        });
      setRoomTypeRates(result);
      ShowRoomTypeRates();
    };

    function ShowRoomTypeRates() {
        let roomTypePanel = <div />;
        let roomTypeContent = <div />;
        if (roomTypeRates !== null) {
            roomTypeContent = roomTypeRates.map((rate) => 
                <tr>
                  <td>
                    <label>{rate.roomType} for {rate.nightCount} Night(s) in Range 
                    <select width="75px" onChange={(event) => updateRoomTypes(event, rate.rateScheduleTypeId, "daterange")} value={rate.dateRangeId}>{
                      dateRanges.map((dr) => {
                        return <option key={dr.id} value={dr.id}>{new Date(dr.lowValue).toLocaleDateString('en-US')}-{new Date(dr.highValue).toLocaleDateString('en-US')}</option>
                      })
                    }
                    </select></label></td><td><input type="number" min="0" step=".01" value={rate.amount} onChange={(e) => updateRoomTypes(e, rate.rateScheduleTypeId, "amount")}/></td></tr>
            );
            roomTypePanel = (<div id="roomTypes">
                            {/* <label><b>{new Date(roomTypeRates[0].lowDate).toLocaleDateString('en-US')}-{new Date(roomTypeRates[0].highDate).toLocaleDateString('en-US')}</b> */}
                            <div><button className='button' onClick={() => UpdateRates("RoomType")}>Update Rates</button></div>
                                <table>{roomTypeContent}</table>
                            {/* </label> */}
                            
                        </div>);
        } else {
          
          //see if they want to add a new age rate set and then show the table for entry
          if (window.confirm("There are not any Room Type Rates for this Rate Schedule. Would you like to add some?")) {
            if (dateRanges !== null) {
              let sd = dateRanges[0].id;
              const dateRangeSelect =   
                <select width="75px" onChange={(event) => sd = parseInt(event.target.value)}> {
                  dateRanges.map((dr) => {
                    return <option key={dr.id} value={dr.id}>{new Date(dr.lowValue).toLocaleDateString('en-US')}-{new Date(dr.highValue).toLocaleDateString('en-US')}</option>
                    })
                  }
                </select>;
              var rs = rateSchedules.find(rs => rs.rateScheduleId === selectedRateSchedule);
              
              const json = JSON.stringify(roomTypes);
              const roomTypeSet = JSON.parse(json);
              roomTypeContent = roomTypeSet.map((range) => 
                  <tr><td><label>{range.roomTypeName} for {rs.nightCount} Night(s) in selected Date Range</label></td><td><input type="number" min="0" step=".01" value={range.amount} onChange={(e) => range.amount = parseInt(e.target.value)}/></td></tr>
              );
              roomTypePanel = (<div id="roomTypes">
                              {dateRangeSelect}
                                <div><button className='button' onClick={() => AddRates("RoomType", roomTypeSet, rs, sd)}>Add Rates</button></div>
                                  <table>{roomTypeContent}</table>                              
                          </div>);
            } else {
              alert("No Date Ranges have been found. Please check the Date Range table to ensure data is available.")
            }            
          } 
        }
        setRightPanel(roomTypePanel);
    }

    useEffect(() => {
        if (rateSchedules === null) {
            GetRateSchedules();
        }      
    }, [GetRateSchedules, rateSchedules]);

    useEffect(() => {
        GetRateSchedules();
    }, [activeFlag, GetRateSchedules])

    useEffect(() => {
        if (rateSchedules !== null && selectedRateSchedule !== null) {
          if (selectedRateSchedule > 0) {
            const rateSchedule = rateSchedules.find(rs => rs.rateScheduleId === selectedRateSchedule);
            setRateScheduleName(rateSchedule.rateScheduleName);
            setRateScheduleDescription(rateSchedule.rateScheduleDescription);
            setNightCount(rateSchedule.nightCount);
            setHallMonitoringAmount(rateSchedule.hallMonitoringAmount);
            setActiveValue(rateSchedule.active);
            setContiguousNightAmount(rateSchedule.contiguousNightAmount);
            setNightlySchedule(rateSchedule.nightlyScheduleId);
            GetRateScheduleRoomTypes();
            GetRateScheduleAges();
            setRightPanel(<div />);
          } else {
            setRateScheduleName("");
            setRateScheduleDescription("");
            setNightCount(0);
            setHallMonitoringAmount(0.00);
            setActiveValue(true);
            setContiguousNightAmount(0.00);
            setNightlySchedule(3);
            setNewRateSchedule(true);
            setRightPanel(<div />);
          }            
        }       
               
    }, [selectedRateSchedule, GetRateScheduleAges, GetRateScheduleRoomTypes, rateSchedules])

        
     const rateScheduleSelect = rateSchedules === undefined || rateSchedules === null ? <select /> : 
      <select width="75px" onChange={(event) => setSelectedRateSchedule(parseInt(event.target.value))} value={selectedRateSchedule}> {
        rateSchedules.map((rs) => {
          return <option key={rs.rateScheduleId} value={rs.rateScheduleId}>{rs.rateScheduleName}</option>
          })
        }
      </select>;
    
    const nightlyScheduleSelect = nightlySchedules === undefined || nightlySchedules == null ? <select /> :
        <select width="75px" onChange={(event) => setNightlySchedule(parseInt(event.target.value))} value={nightlySchedule}> {
          nightlySchedules.map((ns) => {
            return <option key={ns.rateScheduleId} value={ns.rateScheduleId}>{ns.rateScheduleName}</option>
          })
        }
        </select>;
    
    function SetActiveAndReset(checked) {
      setSelectedRateSchedule(null);
      setActiveFlag(checked);
    }
 
    const cancelButton = newRateSchedule ? <button className='button' onClick={() => setNewRateSchedule(false)}>Cancel Add</button> : <div />;
    const selectRateScheduleArea = !newRateSchedule ? (<div><label>
                  Rate Schedule
                  {rateScheduleSelect}
              </label>
              <label>
                  Active Only
                  <input type="checkbox" checked={activeFlag} onChange={e => SetActiveAndReset(e.target.checked)}/>
              </label></div>) : <div />;
    return (
        <div className='checkIn-content-tabbed'>
          <div>
            {responseMsg}  
            <button className='button' onClick={(() => setSelectedRateSchedule(-1))}>Add New Rate Schedule</button>                                
            {selectRateScheduleArea}            
            {cancelButton}
            </div>
            <div className="sideBySide">
                <div style={{width: '40%'}}>                     
                    <div><b>Rate Schedule Details</b></div> 
                    <div>
                        <button className='button' onClick={() => SaveRateSchedule()}>Update Rate Schedule</button>
                    </div>
                    <div>
                        <label>
                            Rate Schedule Name
                            <input type="text" value={rateScheduleName} onChange={e => setRateScheduleName(e.target.value)} />
                        </label>
                    </div>                    
                    <div>
                        <label>
                            Rate Schedule Description
                            <input type="text" value={rateScheduleDescription} onChange={e => setRateScheduleDescription(e.target.value)} />
                        </label>
                    </div>
                    <div>
                        <label>
                            Night Count
                            <input type="number" min="0" step="1" value={nightCount} onChange={e => setNightCount(parseInt(e.target.value))} />
                        </label>
                    </div>
                    <div>
                        <label>
                            Hall Monitoring Amount
                            <input type="number" min="0.00" step=".01" value={hallMonitoringAmount} onChange={e => setHallMonitoringAmount(parseFloat(e.target.value))} />
                        </label>
                    </div>
                    <div>
                        <label>
                            Active
                            <input type="checkbox" checked={activeValue} onChange={e => setActiveValue(e.target.checked)} />
                        </label>
                    </div>
                    <div>
                        <label>
                            Contiguous Night Amount
                            <input type="number" min="0.00" step=".01" value={contiguousNightAmount} onChange={e => setContiguousNightAmount(parseFloat(e.target.value))} />
                        </label>
                    </div>
                    <div>
                        <label>
                          Nightly Schedule
                          {nightlyScheduleSelect}
                        </label>
                    </div>                    
                </div>
                <div style={{width: '60%'}}>
                    <div>
                        <button className='button' onClick={ShowAgeRates}>Show Rates By Age</button>
                        <button className='button' onClick={ShowRoomTypeRates}>Show Rates by Room Type</button>
                    </div>
                    <div style={{maxHeight: "300px", overflowY: "auto"}}>
                      {rightPanel}
                    </div>                   
                </div>
            </div>
        </div>
    )
}