import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MODE, SERVICE_URL} from '../../controls/Shared';
import { ChargesAndPayments } from './ChargesAndPayments';
import { LengthOfStay } from './LengthOfStay';
import { ShiftBeds } from './ShiftBeds';

export function HousingDetails(props) {

    const { BedId, OdDate, BackToOd, userInfo } = props;
    const [housingAssignments, setHousingAssignments] = useState([]);
    const [haArea, setHaArea] = useState(<div>LOADING!!</div>);
    const [selectedGuest, setSelectedGuest] = useState(null);
    const [visibleTab, setVisibleTab] = useState("1");

    const dateParts = OdDate.split("/");
    const reqDate = useMemo(() => new Date(dateParts[2], dateParts[0]-1, dateParts[1]), [dateParts]); 

    const GetHousingAssignments = useCallback((incomingBed) => {
        const body = JSON.stringify({
            "BedId" : incomingBed,
            "OdDate" : reqDate,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/getHousingAssignments";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 406) {
                //setResponseMsg("No Events found.");
              } else {
                //setResponseMsg("There was a problem with the request for Event data.");
              }
              
              return null;
            }
        })
        .then((responseJson) => {
            //if (responseJson !== null) setResponseMsg("");
            setHousingAssignments(responseJson);
        });
    }, [reqDate, userInfo.userName]);

    function UpdateHousing(checkIn, checkOut, bedId, message) {
      const body = JSON.stringify({
        "HousingAssignmentId" : selectedGuest.housingAssignmentId,
        "BedId" : bedId,
        "CheckIn": checkIn,
        "CheckOut": checkOut,
        "TestMode": MODE === "TEST",
        "Requestor": userInfo.userName
      });
        const url = SERVICE_URL + "/updateHousing";
        
            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
              if (response.ok) {
                alert(message);
                GetHousingAssignments(bedId);
              } else {
                alert("A Problem Occurred", response.value);
              }
            });
    }

    const SelectRegistrant = useCallback((regId) => {
      const selectedReg = housingAssignments.filter((ha) => {
        return ha.eventRegistrationId === regId;
      });
      setSelectedGuest(selectedReg[0]);
    }, [housingAssignments, setSelectedGuest]);

    const data = [
        {id : '1',
         tabTitle: "Length Of Stay",
         tabContent: <LengthOfStay DateSelected={reqDate} SelectedGuest={selectedGuest} UpdateHousing={UpdateHousing} userInfo={userInfo}/>
        },
        {id : '2',
         tabTitle: "Shift Beds",
         tabContent: <ShiftBeds DateSelected={reqDate} SelectedGuest={selectedGuest} UpdateHousing={UpdateHousing} userInfo={userInfo}/>
        },
        {id : '3',
         tabTitle: "Charges And Payments",
         tabContent: <ChargesAndPayments SelectedGuest={selectedGuest} userInfo={userInfo}/>
        }       
      ]
      
      function Tab(props){
        
        const listTitles = props.data.map((item) => 
            <li onClick={() => setVisibleTab(item.id)} className={visibleTab === item.id ? "tab-title tab-title--active" : "tab-title"}>{item.tabTitle}</li>
        )       
                                         
        const listContent = props.data.map((item) => 
            <p style={visibleTab === item.id ? {} : {display: 'none'}}>{item.tabContent}</p>
        )
        
        return(
            <div className="tabs">
              <ul className="tabs-titles">
                {listTitles}
              </ul>
              <div className="tab-content">
                 {listContent}
              </div>
            </div>
          )
      }
    
    useEffect(() => {
        if (housingAssignments === null || housingAssignments.length === 0) {
            GetHousingAssignments(BedId);
        }        
    }, [GetHousingAssignments, housingAssignments, BedId]);

    useEffect(() => {
        if (housingAssignments !== null) {
            if (housingAssignments.length > 0) {
                const dropDown = <select onChange={(event) => SelectRegistrant(parseInt(event.target.value))} defaultValue={housingAssignments[0]}> {
                    housingAssignments.map((ha) => {
                    return <option key={ha.eventRegistrationId} value={ha.eventRegistrationId}>{ha.fullName}</option>
                    })
                    }
                </select>;
                setSelectedGuest(housingAssignments[0]);
                setHaArea(<div><i>Housing Assignment for {housingAssignments[0].fullName}</i><div>{dropDown}</div></div>);
            } else {
                setHaArea(<i>No Housing Assignments Found</i>);
            }            
        } 
    }, [housingAssignments, SelectRegistrant]);

    return (
        <div>
            {haArea}
            <div>
                <button className='button' onClick={(() => BackToOd())}>Back To Occupancy Display</button>
            </div>
            <div>
                <Tab data={data} />
            </div>
        </div>
    )
}