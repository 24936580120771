
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MODE, SERVICE_URL, Styles } from '../../controls/Shared';
import { useTable } from 'react-table'

export function ShiftBeds(props) {

    const { SelectedGuest, UpdateHousing, userInfo } = props;
    const [allBeds, setAllBeds] = useState(null);
    const [currentBed, setCurrentBed] = useState(null);

    const { current: columns } = useRef(
        [
            {
                Header: "Building",
                accessor: "buildingName"
            },
            {
                Header: "Room Name",
                accessor: "roomName"
            },
            {
              Header: "Guest In Bed / Bed Type",
              accessor: "fullName"
            },
            {
              Header: "bedType",
              accessor: "bedType"
            }  
        ]
      ); 

    const GetAssignedBeds = useCallback(() => {
        const body = JSON.stringify({
            "StartDate" : SelectedGuest.checkIn,
            "EndDate" : SelectedGuest.checkOut,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
        });

        const url = SERVICE_URL + "/getAllBeds";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
            'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
            return response.json();
            } else {
            if (response.status === 406) {
                //setResponseMsg("No Events found.");
            } else {
                //setResponseMsg("There was a problem with the request for Event data.");
            }
            
            return null;
            }
        })
        .then((responseJson) => {
            //if (responseJson !== null) setResponseMsg("");
            setAllBeds(responseJson);
        });
    }, [SelectedGuest, userInfo.userName]);

    function AssignGuest(bedId) {
        if (window.confirm("Are you certain you want to move this guest to a new bed?")) {
          UpdateHousing(SelectedGuest.checkIn, SelectedGuest.checkOut,bedId, "Guest has been moved to a new bed!");
        }
    }

    useEffect(() => {
        if (SelectedGuest != null && allBeds === null) {
            GetAssignedBeds();
        }
    }, [SelectedGuest, allBeds, GetAssignedBeds])

    useEffect(() => {
      if (allBeds !== null) {
        const guestBed = allBeds.filter(function (bed) {
          return bed.bedId === SelectedGuest.bedId;
        })
        setCurrentBed(guestBed);
      }
    }, [allBeds, SelectedGuest]);

    function Table({ columns, data, hiddenFields }) {
        // Use the state and functions returned from useTable to build your UI
        const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow,
        } = useTable({
          columns,
          data,
          initialState : {
              hiddenColumns: hiddenFields
          }
        })
      
        // Render the UI for your table
        return (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                if (row.original.fullName === row.original.bedType || (row.original.bedType === "Double" && !row.original.fullName.includes("&"))) {
                  return (
                    <tr className="clickableArea" {...row.getRowProps()} onClick={() => AssignGuest(row.original.bedId)}>
                      {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                } else {
                  return (
                    <tr style={{backgroundColor: 'lightgrey', color: 'darkgrey'}} {...row.getRowProps()} >
                      {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                }                
              })}
            </tbody>
          </table>
        )
      }
      let bedTable = <div />;
      let dateLabel = <div />;
      let bedLabel = <div />;
      if (allBeds !== null && currentBed !== null) {
          dateLabel = <label>{allBeds[0].dateRange}</label>;
          bedLabel = <label>{SelectedGuest.fullName} is currently in a {currentBed[0].bedType} in room {currentBed[0].roomName}</label>
          bedTable = <Table className="table" columns={columns} data={allBeds} hiddenFields={["bedType"]}/>; 
      }
    if (allBeds !== null && currentBed !== null) {
      return (
        <div>
          <div>{dateLabel}</div>
          <div>{bedLabel}</div>
          <div className="bedGrid">
            <Styles>                
                {bedTable}
            </Styles> 
          </div>        
        </div>
      );
    } else {
      return (
        <div>
          Loading Bed Data...      
        </div>
    )
    }
    
}


