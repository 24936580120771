import React, { useState, useRef, useCallback, useMemo } from 'react';
import { useEffect } from 'react';
import { MODE, SERVICE_URL, DataYear, Table, Styles } from '../controls/Shared';
import Popup from '../controls/Popup';
import { Bill } from '../controls/Bill';

export default function Reporting(props) {

    const { paymentMethods, billableItemCategories, userInfo } = props;
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState(<div />); 
    const [reportTableData, setReportTableData] = useState(null);
    const [billSummary, setBillSummary] = useState(null);
    const [buttonText, setButtonText] = useState("Run and Export Report");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    
    function ClosePopup() {
        setPopupContent(null);
        setShowPopup(false);
    } 

    const { current: billSummaryColumns } = useRef(
        [
            {
                Header: "Date",
                accessor: "itemDate",
                Cell: row => {
                    return(
                      <div>{new Date(row.value).toLocaleDateString('en-US')}</div>
                    )
                  }
            },
            {
                Header: "Description",
                accessor: "description"
            },
            {
                Header: "Charges",
                accessor:"charge",
                Cell: row => {
                    if (row.value === 0.00) {
                        return <div />;
                    } else {
                        return(
                        <div>{row.value.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}</div>
                        )
                    }
                  }
            },
            {
                Header: "Payments",
                accessor: "credit",
                Cell: row => {
                    if (row.value === 0.00) {
                        return <div />;
                    } else {
                        return(
                        <div>{row.value.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}</div>
                        )
                    }
                  }
            }
        ]
    );

    const GetEventRegistration = useCallback((erId) => {
        if (erId  && erId !== null && erId !== "") {
            const body = JSON.stringify({
                "EventRegistrationId" : erId,
                "RequestType" : "Bill",
                "TestMode" : MODE === "TEST",
                "Requestor" : userInfo.userName
              });
    
              const url = SERVICE_URL + "/getEventRegistrations";
            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                  'Content-Type': 'application/json'
                }
              }).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 406) {
                        alert("Reg not found?!");
                    } else {
                        alert("Something went wrong. See Event Logs for details");
                    }              
                    return null;
                }
            })
            .then((responseJson) => {
                setSelectedEvent(responseJson[0]);
            });
        }        
    }, [userInfo.userName]);

    const GetBillSummary = useCallback((erId) => {
        const body = JSON.stringify({
            "EventRegistrationId" : erId,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/getBillSummary";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return null;
            }
        })
        .then((responseJson) => {
            //if (responseJson !== null) setResponseMsg("");
            setBillSummary(responseJson);
            GetEventRegistration(erId);
        });
    },[userInfo.userName, GetEventRegistration]);    

    const { current: columns } = useRef([
               {    
            Header: "Full Name",
            accessor: "FullName"
        },
        {
            Header: "Event Name",
            accessor: "EventName"
        },
        {
            Header: "Outstanding Balance",
            accessor: "OutstandingBalance"
        },
        {
            Header: "Building",
            accessor: "Building"
        },
        {
            Header: "",
            accessor: "erId",
            Cell: row => {
              return(
                <button className='button' onClick={() => GetBillSummary(row.value)}>Print Bill</button>
              )
            }
        }
    ]);

    const { current: reports } = useRef([
           {
            label: "",
            value: "noReport",
            type: "none"
           },
           {
            label: "Arrivals and Departures",
            value: "RPT_GetArrivalsAndDepartures",
            type: "date"
           },
           {
            label: "Conferee Balance Due",
            value: "RPT_GetConfBalanceDue",
            type: "conference"
           },
           {
            label: "Non-Checked In People by Conference",
            value: "RPT_GetUncheckedInGuests",
            type: "conference"
           },
           {
            label: "Occupancy",
            value: "RPT_GetOccupancy",
            type: "date"
           },
           {
            label: "Unpaid Bills by Depature Date",
            value: "RPT_GetUnpaidBillsByDeparture",
            type: "date"
           }
        ]);
    
    const [years, setYears] = useState([]);
    const currentYear = DataYear;
    const [selectedReport, setSelectedReport] = useState(null);
    const [filters, setFilters] = useState(null);
    const [billCatSelect, setBillCatSelect] = useState(null);
    const [methodSelect, setMethodSelect] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedCat, setSelectedCat] = useState(null);
    //const [selectedConf, setSelectedConf] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [eventName, setEventName] = useState("");
    const [events, setEvents] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [resultMsg, setResultMsg] = useState("");

    const yearSelect = useMemo(() => years === undefined ? <div /> : (
    <select onChange={(event) => setSelectedYear(parseInt(event.target.value))}> {
        years.map((year) => {
        return <option key={year} value={year}>{year}</option>
        })
        }
    </select>), [years]);

    const ShowPrintBill = useCallback((chg, pmt) => {
        setPopupContent(<Bill eventRegistration={selectedEvent} billSummaryColumns={billSummaryColumns} billSummary={billSummary} totalCharge={chg} totalPayment={pmt} userInfo={userInfo}/>);
        setShowPopup(true);
    }, [billSummaryColumns, billSummary, userInfo, selectedEvent]);

    

    const GetEvents = useCallback(() => {
        const body = JSON.stringify({
            "Year" : selectedYear,
            "Name": eventName,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/getEvents";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return null;
            }
        })
        .then((responseJson) => {
            //if (responseJson !== null) setResponseMsg("");
            setEvents(responseJson);
        });
    }, [selectedYear, eventName, userInfo.userName]);

    const handleKeypress = useCallback((e) => {
        //it triggers by pressing the enter key
      if (e.charCode === 13) {
        GetEvents();
      }
    },[GetEvents]); 

    const SelectFilters = useCallback((reportType) => {
        switch (reportType) {
            case "year":
                return <div>Select A Year{yearSelect}</div>;
            case "yearCat":
                return <div><div>Select A Year{yearSelect}</div><div>Select a Billable Item Category{billCatSelect}</div></div>;
            case "yearMethod":
                return <div><div>Select A Year{yearSelect}</div><div>Select a Payment Method{methodSelect}</div></div>;
            case "conference":
                return <div>
                            <div>
                                {yearSelect}
                                <input type="text" value={eventName} onChange={(event) => setEventName(event.target.value)} onKeyPress={handleKeypress}/>
                                <button className='button' onClick={() => GetEvents()}>Search</button>
                            </div>
                            <div className="boxedContent">                
                                <div className='item-list'>
                                    {events !== null ? (events.map((item) => (
                                        <div className='item-container'>
                                            <div className='item-name' onClick={() => toggleComplete(item)}>
                                            {item === selectedEvent ? (
                                                <span className={'highlighted-unbalanced'}>{item.eventName}</span>
                                                ) : (       
                                                <span className={'unbalanced'}>{item.eventName}</span>
                                                )}
                                            </div>
                                        </div>
                                    ))) : <div />}
                                </div>
                            </div>
                        </div>;
            case "date":
                return <div>Select A Date for the Report<input type="date" onChange={e => setSelectedDate(e.target.value)}/></div>;
            default:
                return <div />;
        }
    }, [eventName, events, selectedEvent, GetEvents, billCatSelect, yearSelect, methodSelect, handleKeypress]);

    useEffect(() => {
        if (selectedEvent !== null && billSummary != null) {
            let chg = 0.00;
            let pmt = 0.00;
            let eventNames = [];
            billSummary.forEach((billItem) => {
                chg += billItem.charge;
                pmt += billItem.credit;
                if (!eventNames.includes(billItem.eventName)) {
                    eventNames.push(billItem.eventName);
                }
            });
            ShowPrintBill(chg, pmt);
        }        
    }, [selectedEvent, billSummary, ShowPrintBill]);

    useEffect(() => {
        if (years.length === 0) {        
            const filledYears = [];
            for (var i = currentYear; i >= currentYear - 10; i--) {
              filledYears.push(i);
            }
            setYears(filledYears);
          }  
    }, [years.length, currentYear])



    useEffect(() => {
        if (billableItemCategories && billableItemCategories !== null) {
            const billCatDropdown = (<select onChange={(event) => setSelectedCat(event.target.value)}>
            {
                billableItemCategories.map((cat) => {
                return <option key={cat.billableItemCategoryId} value={cat.billableItemCategoryId}>{cat.billableItemCategoryName}</option>
                })
            }
        </select>);
        setBillCatSelect(billCatDropdown);
        }        
    }, [billableItemCategories])

    useEffect(() => {
        if (paymentMethods && paymentMethods !== null) {
            const methodDropdown = (<select onChange={(event) => setSelectedPaymentMethod(event.target.value)}>
                {
                    paymentMethods.map((pm) => {
                        return <option key={pm.paymentMethodId} value={pm.paymentMethodId}>{pm.paymentMethodName}</option>
                    })
                }
            </select>)
            setMethodSelect(methodDropdown);
        }
    },[paymentMethods]);

    useEffect(() => {
        const reportType = reports.filter((report) => {
            return report.value === selectedReport;
        }).map((report) => {
            return report.type;
        })[0];
        if (selectedReport !== "RPT_GetUnpaidBillsByDeparture" 
            || (selectedReport === "RPT_GetUnpaidBillsByDeparture" && selectedEvent === null)) {
            setReportTableData(null);
        }
        setFilters(SelectFilters(reportType));
    }, [selectedReport, eventName, events, selectedEvent, SelectFilters, reports]);

    const toggleComplete = (event) => {
        setSelectedEvent(event);
    };

    

    function RunReport() {
        if (selectedReport !== null) {
            setButtonText("Running Report...");
            setButtonDisabled(true);
            setReportTableData(null);
            const reportType = reports.filter((report) => {
                return report.value === selectedReport;
            }).map((report) => {
                return report.type;
            })[0];
            const reportLabel= reports.filter((report) => {
                return report.value === selectedReport;
            }).map((report) => {
                return report.label;
            })[0];
            let body = {};
            body.procName = selectedReport;
            body.reportType = reportType;
            switch(reportType) {
                case "year":
                    body.year = selectedYear;                    
                    break;
                case "yearCat":
                    body.year = selectedYear;
                    body.billableItemCategoryId = parseInt(selectedCat);
                    break;
                case "yearMethod":
                    body.year = selectedYear;
                    body.paymentMethodId = parseInt(selectedPaymentMethod);
                    break;
                case "conference":
                    body.eventId = selectedEvent.eventId;
                    break;
                case "date":
                    body.reportDate = selectedDate;
                    break;
                default:
                    alert("unknown report type");
                    break;
            }
            GetReportData(body, reportLabel);
            
        } else {
            alert("Please selected a Report Type!");
        }        
    }

    function GetReportData(body, reportLabel) {
        body.TestMode = MODE === "TEST";
        body.Requestor = userInfo.userName;
        const url = SERVICE_URL + "/adminReport";
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
                setButtonText("Run and Export Report");
                setButtonDisabled(false);
                alert("There has been an issue getting the report. Please see the Event Logs for more detail.")
                return null;
            }
        })
        .then((responseJson) => {
            const currDate = new Date();
            let fileName = reportLabel.replace(" ","") + " - " + currDate.toLocaleDateString("en-us");
            //if (responseJson !== null) setResponseMsg("");
            setButtonText("Run and Export Report");
            setButtonDisabled(false);
            if (responseJson !== null && responseJson.length > 0) {
                setResultMsg("");
                if (reportLabel === "Unpaid Bills by Depature Date") {
                    setReportTableData(responseJson);
                } else {
                    setReportTableData(null);
                }
                exportReport(responseJson, fileName);
            } else {                
                setResultMsg("No results found");
            } 
        });
    }

    function exportReport(json, name) {
        let csv = "";
        let accessors = [];
        let row = [];
        const data = json;
        const headers = Object.keys(json[0]).map((key, id)=>{
            return {
              Header: key,
              accessor: key
            }
          });
        const filename = name + ".csv";
        for (let i = 0; i < headers.length; i++) {
            if (headers[i].accessor !== "erId") {
                row.push(headers[i].Header);
                accessors.push(headers[i].accessor);
            }            
        }
        csv = csv + (row.join(",") + "\n");
        for (let i = 0; i < data.length; i++) {
            let tRow = [];
            const dRow = data[i];
            for (let j = 0; j < accessors.length; j++) {
                const accessor = accessors[j];
                var val = "";
                if (accessor === "Arrival" || accessor === "Departure" || accessor === "BirthDate") 
                {
                    var dateValue = new Date(dRow[accessor]).toLocaleDateString('en-us');
                    val = '"' + dateValue + '"';
                } else {

                    val = '"' + dRow[accessor] + '"';
                }                
                tRow.push(val.replace(null, ''));              
            }
            csv = csv + (tRow.join(",") + "\n");            
        }
        let csvFile;
        csvFile = new Blob([csv], {type: "text/csv;encoding:utf-8" });
        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
            window.navigator.msSaveBlob(csvFile, filename);
        } else {
            let downloadLink;
            downloadLink = document.createElement("a");
            downloadLink.download = filename;
            downloadLink.href = window.URL.createObjectURL(csvFile);
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }
      }    
    const popup = showPopup ? <Popup closePopup={ClosePopup} enablePrint={true}>{popupContent}</Popup> : undefined;
    const reportTable = reportTableData === null ? <div /> : <Table className="table" columns={columns} data={reportTableData} hiddenFields={[]}/>;

    return (
        <div className="checkin-content-tabbed">
            Select A Report
            <select onChange={(event) => setSelectedReport(event.target.value)}>
                {
                    reports.map((report) => {
                    return <option key={report.value} value={report.value}>{report.label}</option>
                    })
                }
            </select>
            <div>
                {filters}
            </div>
            <div>
                <button className='button' onClick={() => RunReport()} disabled={buttonDisabled}>{buttonText}</button>
            </div>
            <div>
                <label style={{color: "red"}}>{resultMsg}</label>
            </div>
            <div className="ciContentArea">           
                  <Styles>
                    {reportTable}
                 </Styles>
            </div>            
            {popup}
        </div>
    )
}