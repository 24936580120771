import React, { useState, useEffect, useCallback } from 'react';
import { MODE, Styles, SERVICE_URL, DataYear } from '../../controls/Shared';

export default function PosAdmin(props) {

    const { userInfo } = props;
    
    const [years, setYears] = useState([]);
    const currentYear = DataYear;
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [pointOfSales, setPointOfSales] = useState(null);
    const [userMsg, setUserMsg] = useState("");



    const deleteInventory = (body) => {
        const url = SERVICE_URL + "/deletePointOfSale";
        
            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then(json => {
                if (json.status !== 200) {
                  alert("There has been an issue with the deletion. Please see the Event Logs for more detail. ")
                } else {
                  GetPointOfSales();
                }              
            })
      }

    const onDelete = ({ chargeId, paymentId }) => {

    if(window.confirm("Are you certain you want to delete this entry?")) {
        const body = JSON.stringify({
        "ChargeId" : chargeId,
        "PaymentId" : paymentId,
        "TestMode" : MODE === "TEST",
        "Requestor" : userInfo.userName
        });
        deleteInventory(body);
    }      
    }
      
    const GetPointOfSales = useCallback(() => {
        setPointOfSales(null);
        setUserMsg("Getting unbatched Point of Sales for " + selectedYear);
        const body = JSON.stringify({
                "Year" : selectedYear,
                "TestMode" : MODE === "TEST",
                "Requestor" : userInfo.userName
            });
        const url = SERVICE_URL + "/getPointOfSales";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                if (response.status === 406) {
                    setUserMsg("No unbatched Point of Sales have been found for " + selectedYear);
                } else {
                    setUserMsg("There was a problem collecting the Point of Sales. Please check Event Logs for more details.")
                }                
                return null;
            }
        })
        .then((responseJson) => {
            if (responseJson !== null) setUserMsg("");
            setPointOfSales(responseJson);
        });
    }, [selectedYear, userInfo.userName]);

    useEffect(() => {
    if (years.length === 0) {        
        const filledYears = [];
        for (var i = currentYear; i >= currentYear - 10; i--) {
        filledYears.push(i);
        }
        setYears(filledYears);
    }      
    }, [years.length, currentYear]);  
  
    const yearSelect = years === undefined ? <div /> : 
    <select onChange={(event) => setSelectedYear(parseInt(event.target.value))}> {
        years.map((year) => {
            return <option key={year} value={year}>{year}</option>
        })
        }
    </select>;

    let posTable = <div />;
    if (pointOfSales !== null) {
        posTable = <table>
            <thead>
                <tr>
                    <th>Charge ID</th>
                    <th>Payment ID</th>
                    <th>Notes</th>
                    <th>POS Date</th>
                    <th>POS Amount</th>
                    <th>Added By</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {
                pointOfSales.map((item) => (
                    <tr key={item.posId}>
                        <td>{item.chargeId}</td>
                        <td>{item.paymentId}</td>
                        <td>{item.notes}</td>
                        <td>{new Date(item.posDateTime).toLocaleDateString('en-US')}</td>
                        <td>{item.posAmount.toLocaleString('en-US', {style: 'decimal',minimumFractionDigits: 2})}</td>
                        <td>{item.addedBy}</td>
                        <td>
                            <button
                                className={"btn-primary"}
                                onClick={() => onDelete({chargeid: item.chargeId, paymentId:item.paymentId})}
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    }



    return (
        <div>
            <label>Event Year</label>
            {yearSelect}
            <button className='button' onClick={() => GetPointOfSales()}>Get PoS</button>
            <div className="contentArea">  
                {userMsg}                            
                <Styles>
                    {posTable}
                </Styles>
            </div>                
        </div>
    );
}