import './App.css';
import Container from './layouts/Container';

function App() {
  return (
    <div style={{overflow: "hidden"}}>
      <Container />
    </div>
  );
}

export default App;