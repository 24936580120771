import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { MODE, Styles, SERVICE_URL } from '../controls/Shared';
import { useTable } from 'react-table'
import {HousingDetails} from './OccupancyDisplayPages/HousingDetails';

export default function OccupancyDisplay(props) {

    const { buildings, userInfo } = props;

    const [requestDate, setRequestDate] = useState("");
    const [occupancyDisplayData, setOccupancyDisplayData] = useState(null);
    const [odTable, setOdTable] = useState(<div />);
    const [responseMsg, setResponseMsg] = useState("");
    const [dateHeaders, setDateHeaders] = useState([]);
    const [selectedHousing, setSelectedHousing] = useState({});
    const [showOd, setShowOd] = useState(true);
    const [selectedBuilding, setSelectedBuilding] = useState("All");
    const [odGridScrollPosition, setOdGridScrollPosition] = useState(0);

    const headers = useMemo(() =>
        [
            {
                Header: 'Building',
                accessor: 'BuildingName'
            },
            {
                Header: 'Room',
                accessor: 'RoomName'
            },
            {
                Header: "BedId",
                accessor: "BedId"
            },
            {
                Header: "Bed",
                accessor: "BedType"
            },
            {
                Header: "SortId",
                accessor: "SortId"
            },
            {
                Header: "RoomNumber",
                accessor: "RoomNumber"
            },
            {
                Header: dateHeaders[0],
                accessor: "Day1"
            },
            {
                Header: dateHeaders[1],
                accessor: "Day2"
            },
            {
                Header: dateHeaders[2],
                accessor: "Day3"
            },
            {
                Header: dateHeaders[3],
                accessor: "Day4"
            },
            {
                Header: dateHeaders[4],
                accessor: "Day5"
            },
            {
                Header: dateHeaders[5],
                accessor: "Day6"
            },
            {
                Header: dateHeaders[6],
                accessor: "Day7"
            },
            {
                Header: dateHeaders[7],
                accessor: "Day8"
            },
            {
                Header: "",
                accessor: "CellColor"
            }
        ]
      ,[dateHeaders]);

      const SetDateHeaders = useCallback(() => {
        let dateRange = [];
        const dateParts = requestDate.split("-");
        const reqDate = new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        let changeDate = new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
        changeDate.setDate(changeDate.getDate()-1);
        dateRange.push(changeDate.toLocaleDateString("en-us"));
        dateRange.push(reqDate.toLocaleDateString("en-us"));
        changeDate.setDate(changeDate.getDate()+2);
        dateRange.push(changeDate.toLocaleDateString("en-us"));
        changeDate.setDate(changeDate.getDate()+1);
        dateRange.push(changeDate.toLocaleDateString("en-us"));
        changeDate.setDate(changeDate.getDate()+1);
        dateRange.push(changeDate.toLocaleDateString("en-us"));
        changeDate.setDate(changeDate.getDate()+1);
        dateRange.push(changeDate.toLocaleDateString("en-us"));
        changeDate.setDate(changeDate.getDate()+1);
        dateRange.push(changeDate.toLocaleDateString("en-us"));
        changeDate.setDate(changeDate.getDate()+1);
        dateRange.push(changeDate.toLocaleDateString("en-us"));
        setDateHeaders(dateRange);
    }, [requestDate, setDateHeaders]);

    const GetOccupancyDisplayData = useCallback(() => {
        if (requestDate === undefined || requestDate === null) {
            alert("Please select a date first");
        } else {
            setResponseMsg("Getting Occupancy Dispay Data...");
            const url = SERVICE_URL + "/getDisplay";
            fetch(url, {
                method: 'POST',
                body: JSON.stringify({ "DisplayDate" : requestDate, "SelectedBuilding" : selectedBuilding, "TestMode" : MODE === "TEST", "Requestor" : userInfo.userName }),
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then((response) => {
                    if (response.ok) {
                      return response.json();
                    } else {
                      throw new Error('Something went wrong');
                    }
                })
                .then((responseJson) => {
                    setResponseMsg("");
                    SetDateHeaders();
                    setOccupancyDisplayData(responseJson);
                    document.getElementById("odGrid").scrollTop = odGridScrollPosition;                                      
                })
                .catch((error) => {
                    setResponseMsg("No Data was found for the Request Date.");
                });     
            
        } 
    }, [odGridScrollPosition, userInfo.userName, requestDate, selectedBuilding, SetDateHeaders, setResponseMsg]);    
    
    function GetHousingDetail(cell) {
        if (cell.value !== null) {
            setOdGridScrollPosition(document.getElementById("odGrid").scrollTop);
            let deets = {};
            deets.BedId = cell.row.original.BedId;
            deets.OdDate = cell.column.Header;
            setSelectedHousing(deets);
            setShowOd(false);
        }        
    }

    function ShowOd() {     
        setOccupancyDisplayData(null);           
        GetOccupancyDisplayData();
        setShowOd(true);
    }

    

    function Table({ columns, data, hiddenFields }) {
        // Use the state and functions returned from useTable to build your UI
        const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow,
        } = useTable({
          columns,
          data,
          initialState : {
              hiddenColumns: hiddenFields
          }
        })
      
        // Render the UI for your table
        return (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                        const guestArray = cell && cell.value ? cell.value.split("&") : [];
                        let fullCellValue = "";
                        let colorValue = "#EEEEEE";
                        guestArray.forEach(element => {
                            const valueArray = element.split("|");
                            colorValue = valueArray.length > 1 ? "#" + valueArray[valueArray.length - 2] : "#EEEEEE";
                            let cellValue = valueArray.length > 0 ? valueArray[0] : "";
                            if (valueArray.length === 3) {
                                if (valueArray[2].trim() === "1") {
                                    cellValue += " \u2713";
                                }
                            }
                            if (fullCellValue === "") {
                                fullCellValue = cellValue;
                            } else {
                                fullCellValue += " & " + cellValue;
                            }
                        });
                        
                        return <td style={{cursor: "pointer", backgroundColor: colorValue}} {...cell.getCellProps()} onClick={() => GetHousingDetail(cell)}>{fullCellValue}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        )
      }

    useEffect(() => {
        if (occupancyDisplayData !== null) {
            setOdTable(<Table className="table" columns={headers} data={occupancyDisplayData} hiddenFields={["BedId","SortId","RoomNumber", "CellColor"]}/>); 
        } 
    }, [occupancyDisplayData, headers]);

    let curr = new Date();
    const date = curr.toISOString().substr(0,10); 

    let odView = <div />;
    if (showOd) {        
        const buildingSelect = buildings === null ? <div /> : <label>Building
        <select onChange={(event) => setSelectedBuilding(event.target.value)}> {
            buildings.map((building) => {
            return <option key={building.buildingId} value={building.buildingName}>{building.buildingName}</option>
            })
            }
        </select></label>;
        odView = occupancyDisplayData !== null ? <div>
        <table>
            <tbody>
                <tr>
                    <td><label>Date<input type="date" value={requestDate} onChange={e => setRequestDate(e.target.value)} defaultValue={date}/></label></td>
                    <td>{buildingSelect}</td>
                    <td><button className='button' onClick={() => GetOccupancyDisplayData()}>Get Display</button></td>

{/*                     <td><button className='button'onClick={() => SetAndCollect("last")}>Previous Week</button></td>
                    <td><button className='button' onClick={() => SetAndCollect("current")}>Today</button></td>
                    <td><button className='button' onClick={() => SetAndCollect("next")}>Next Week</button> </td> */}
                </tr>
            </tbody>
        </table>      
        <div>{responseMsg}</div>
        <div id="odGrid" style={{overflowY: 'scroll', scrollBehavior: 'auto', height: '60vh'}}>
            <Styles>
                {odTable}
            </Styles>
        </div>
    </div> : <div>
        <table>
            <tbody>
                <tr>
                    <td><label>Date<input type="date" value={requestDate} onChange={e => setRequestDate(e.target.value)} defaultValue={date}/></label></td>
                    <td>{buildingSelect}</td>
                    <td><button className='button' onClick={() => GetOccupancyDisplayData()}>Get Display</button></td>

{/*                     <td><button className='button'onClick={() => SetAndCollect("last")}>Previous Week</button></td>
                    <td><button className='button' onClick={() => SetAndCollect("current")}>Today</button></td>
                    <td><button className='button' onClick={() => SetAndCollect("next")}>Next Week</button> </td> */}
                </tr>
            </tbody>
        </table> <div>{responseMsg}</div></div>
    } else {
        odView = <div>
        <HousingDetails BedId={selectedHousing.BedId} OdDate={selectedHousing.OdDate} BackToOd={ShowOd} userInfo={userInfo}/>
    </div>  
    }
    
    return (
        <div>
            {odView}
        </div>           
        
    )
}