import React, { useState, useEffect, useCallback } from 'react';
import { MODE, SERVICE_URL, DataYear } from '../../controls/Shared';


export default function PartyManagement(props) {

    const { userInfo } = props;

    const [years, setYears] = useState([]);
    const currentYear = DataYear;
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [eventName, setEventName] = useState("");
    const [instructions, setInstructions] = useState(<div />);
    const [eventRegistrations, setEventRegistrations] = useState(null);
    const [newDepositAmount] = useState(0.00);
    const [partyMembers, setPartyMembers] = useState(null);
    const [regHasGuest, setRegHasGuest] = useState(false);
    const [partyTitle, setPartyTitle] = useState(<div />);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedParty, setSelectedParty] = useState(null);
    const [rightPanel, setRightPanel] = useState(<div />);
    const [promoteButton, setPromoteButton] = useState(<div />);
    const [isAdd, setIsAdd] = useState(false);
    const [guestResponseMsg, setGuestResponseMsg] = useState("");


    const toggleComplete = (event) => {
        isAdd ? setSelectedParty(event) : setSelectedEvent(event);
    };

    const GetPartyMembers = useCallback((eventRegId) => {
        const body = JSON.stringify({
            "EventRegistrationId" : eventRegId,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/getPartyMembers";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return null;
            }
        })
        .then((responseJson) => {
            //if (responseJson !== null) setResponseMsg("");
            setPartyMembers(responseJson);
        });
    }, [userInfo.userName]);

    const PromoteRegistrant = useCallback(() => {
        const body = JSON.stringify({
          "EventRegistrationId" : selectedEvent.eventRegistrationId,
          "DepositAmount" : parseFloat(newDepositAmount),
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });
          const url = SERVICE_URL + "/promote";
          
              fetch(url, {
                  method: 'POST',
                  body: body,
                  headers: {
                      'Content-Type': 'application/json'
                  }
              }).then(response => {
                if (response.ok) {
                  //alert("Registrant Promoted!");
                  GetPartyMembers();
                } else {
                  alert("Something Went Wrong!");
                }
              });
      }, [selectedEvent, newDepositAmount, GetPartyMembers, userInfo.userName]);

    const ShowPromote = useCallback(() => {
        //Add block to stop promoting a Registrant if they have at least 1 guest
        if (selectedEvent && selectedEvent !== null) {
            if (regHasGuest && selectedEvent.constituentType === "Registrant") {
                alert("This Registrant cannot be Promoted because they have at least one Guest in their Party!");
            } else {
                alert("Remember Deposits may need to be adjusted because of this action!");
                PromoteRegistrant();
            }
            
        } else {
            alert("Please select a Registrant for Promotion!");
        }        
    }, [selectedEvent, PromoteRegistrant, regHasGuest]);

    function ShowAddToParty() {
        if (selectedEvent && selectedEvent !== null) {
            setIsAdd(true);
        } else {
            alert("Please select a Registrant to Add to a Party!");
        }        
    }

    function ClearRegs() {
        setSelectedEvent(null);
        setPartyMembers(null);
        setPartyTitle("");
        setPromoteButton(<div />);
    }

    function CancelAdd() {
        setIsAdd(false);
    }

    function CompleteAdd() {
        if (selectedEvent && selectedEvent !== null) {
            alert("Remember Deposits may need to be adjusted because of this action!")
            AddToParty();
        } else {
            alert("Please select a Party to add Registrant to!");
        }  
    }

    function GetEventRegistrations() {
        setGuestResponseMsg("Getting Guests...");
        const body = JSON.stringify({
            "EventYear" : selectedYear,
            "Name": eventName,
            "RequestType" : "PartyManagement",
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/getEventRegistrations";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
                if (response.status === 406) {
                    setGuestResponseMsg("No Guests found.");
                } else {
                    alert("Something went wrong. See Event Logs for details");
                }              
                return null;
            }
        })
        .then((responseJson) => {
            setGuestResponseMsg("");
            setEventRegistrations(responseJson);
        });
    }

      
    
      function AddToParty() {
        const body = JSON.stringify({
          "EventRegistrationId" : selectedEvent.eventRegistrationId,
          "DepositAmount" : parseFloat(newDepositAmount),
          "PartyRegistrantId" : selectedParty.eventRegistrationId,
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });
          const url = SERVICE_URL + "/addToParty";
          
              fetch(url, {
                  method: 'POST',
                  body: body,
                  headers: {
                      'Content-Type': 'application/json'
                  }
              }).then(response => {
                if (response.ok) {
                  //alert("Registrant Added to Party!");
                  setEventName("");
                  setInstructions(<div />);
                  setRightPanel(<div />); 
                  setEventRegistrations(null); 
                  setIsAdd(false);
                } else {
                  alert("Something Went Wrong!");
                }
              });
      }

    
    
    useEffect(() => {
        if (years.length === 0) {        
          const filledYears = [];
          for (var i = currentYear; i >= currentYear - 10; i--) {
            filledYears.push(i);
          }
          setYears(filledYears);
        }      
      }, [currentYear, years.length]);
      
    useEffect(() => {
        if (selectedEvent !== null) {
            GetPartyMembers(selectedEvent.eventRegistrationId); 
            setPartyTitle(<div>Party Members of <b>{selectedEvent.fullName}</b></div>) 
        }               
    }, [selectedEvent, GetPartyMembers]);

    useEffect(() => {
        if (selectedParty !== null) {
            GetPartyMembers(selectedParty.eventRegistrationId);
            setPartyTitle(<div>Party Members of <b>{selectedParty.fullName}</b></div>) 
        }               
    }, [selectedParty, GetPartyMembers]);

    useEffect(() => {
        if (partyMembers !== null) {
            const partyList = (<div><div className="boxedContent">                
                        <div className='item-list'>
                            {(partyMembers.map((item) => (
                                <div className='item-container'>
                                    <div className='item-name'>
                                    {<span>{item.firstName} {item.lastName} (<i>{item.constituentType}</i>) - {item.eventName}</span>
                                    }
                                    </div>
                                </div>)))}
                        </div>
                    </div></div>
                    );
            if (partyMembers.length > 1) {
                setRegHasGuest(partyMembers.some(function (member) { return member.constituentType === "Guest";}));
                setPromoteButton(<button className='button' onClick={ShowPromote}>Promote</button>)
            } else {
                setRegHasGuest(false);
                setPromoteButton(<div />);
            }
            setRightPanel(partyList);
        }
    }, [partyMembers, newDepositAmount, ShowPromote, selectedEvent, setRegHasGuest, setPromoteButton])

    useEffect(() => {
        if (isAdd) {
            if (selectedEvent !== null) {
                setEventName("");
                setInstructions(<div>Select a Party to add <b>{selectedEvent.fullName}</b> to</div>);
            }
        } else {
            setInstructions(<div />); 
                      
        }
        setRightPanel(<div />);
        setEventRegistrations(null);        
    }, [isAdd, selectedEvent])
     
    
    
    const yearSelect = years === undefined ? <div /> : 
    <select onChange={(event) => setSelectedYear(parseInt(event.target.value))}> {
        years.map((year) => {
        return <option key={year} value={year}>{year}</option>
        })
        }
    </select>;
    const secondaryButton = !isAdd ? <button className='button' onClick={() => ShowAddToParty()}>Add To Party</button> : <div><button className='button' onClick={() => CompleteAdd()}>Complete Add</button><button className='button' onClick={() => CancelAdd()}>Cancel</button></div>;
    
    const handleKeypress = e => {
        //it triggers by pressing the enter key
      if (e.charCode === 13) {
        GetEventRegistrations();
      }
    }; 


    
    return (
        <div className="checkin-content-tabbed">
            <div className="sideBySide">
                <div style={{width: '30%'}}> 
                    <div>
                        {yearSelect}
                        <input type="text" value={eventName} onChange={(event) => setEventName(event.target.value)} onKeyPress={handleKeypress}/>
                        <button onClick={() => GetEventRegistrations(isAdd)}>Search</button>
                    </div>
                    {instructions}
                    <div className="boxedContent">                
                        <div className='item-list'>
                            {eventRegistrations !== null ? (eventRegistrations.map((item) => (
                                <div className='item-container'>
                                    <div className='item-name' onClick={() => toggleComplete(item)}>
                                    <span>{item.fullName} - {item.abbreviation} (<i>{item.constituentType}</i>)</span>
                                    </div>
                                </div>
                            ))) : <div>{guestResponseMsg}</div>}
                        </div>
                    </div>
                    <div>
                        {promoteButton}
                        {secondaryButton}
                        {selectedEvent && selectedEvent !== null ?
                            <button className='button' onClick={() => ClearRegs()}>Cancel</button> : <div />

                        }
                    </div>
                </div>              
                <div style={{width: '20%'}}>
                    {partyTitle}
                    {rightPanel}                    
                </div>
                
            </div>
        </div>
    )
}