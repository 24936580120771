import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MODE, SERVICE_URL, Styles, DataYear } from '../../controls/Shared';
import { useTable } from 'react-table'

export default function AssignHousing(props) {

    const { userInfo, buildings, roomTypes } = props;

    const [events, setEvents] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [bedData, setBedData] = useState(null);
    const [selectedGuest, setSelectedGuest] = useState(null);
    const [selectedBed, setSelectedBed] = useState(-1);
    const [bedDateRange, setBedDateRange] = useState("");
    const [bedGridScrollPosition, setBedGridScrollPosition] = useState(0);
    const [selectedRoomType, setSelectedRoomType] = useState(-1);
    const [showRoomTypes, setShowRoomTypes] = useState(false);
    const [guestResponseMsg, setGuestResponseMsg] = useState("");
    const [bedResponseMsg, setBedResponseMsg] = useState("");
    const [assigningGuest, setAssigningGuest] = useState(false);
    const [selectedBuilding, setSelectedBuilding] = useState("All");

    const [items, setItems] = useState(null);
    const currentYear = DataYear;   

    const GetEvents = useCallback(() => {
        const body = JSON.stringify({
            "NoTemp" : true,
            "Year" : currentYear,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/getEvents";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return null;
            }
        })
        .then((responseJson) => {
            //if (responseJson !== null) setResponseMsg("");
            if (responseJson !== null) {
              responseJson.unshift("");
            }            
            setEvents(responseJson);
        });
    }, [currentYear, userInfo.userName ]);

    

    useEffect(() => {
        if (events === null) {
            GetEvents();            
        } 
        if (selectedEvent !== null) {
            setSelectedEvent(selectedEvent)
        }       
    }, [events, GetEvents, selectedEvent]);

    const GetAssignedBeds = useCallback((eventId, guestName, eventRegistrationId) => {
        setBedResponseMsg("Getting Beds...");
        setBedData(null);
        const body = JSON.stringify({
            "EventId" : eventId,
            "GuestName" : guestName,
            "EventRegistrationId" : eventRegistrationId,
            "Building" : selectedBuilding,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/getHousingData";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {              
              return response.json();
            } else {
              if (response.status === 406) {
                setBedResponseMsg("No Beds found.");
              } else {
                alert("Something went wrong. See Event Logs for details");
              }              
              return null;
            }
        })
        .then((responseJson) => {
            setBedResponseMsg("");
            setBedData(responseJson);      
            
        });
    }, [userInfo.userName, selectedBuilding]);

    const GetUnassignedGuests = useCallback((eventId) => {
        setItems(null);
        setGuestResponseMsg("Getting Guests...");
        const body = JSON.stringify({
            "EventId" : eventId,
            "HasBeenAssigned" : 0,
            "RequestType" : "AssignHousing",
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/getEventRegistrations";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 406) {
                setGuestResponseMsg("No Guests found.");
              } else {
                alert("Something went wrong. See Event Logs for details");
              }              
              return null;
            }
        })
        .then((responseJson) => {
            setGuestResponseMsg("");            
            setItems(responseJson);
        });
    }, [userInfo.userName]);

    useEffect(() => {
        if (selectedEvent !== null) {
          setBedDateRange("");
            GetAssignedBeds(selectedEvent, "", -1);
            GetUnassignedGuests(selectedEvent);
            setShowRoomTypes(false);
        } else {
          setBedResponseMsg("");
          setEvents(null);
          setBedData(null);
          setItems(null);
        }
    }, [selectedEvent, userInfo.userName, GetAssignedBeds, GetUnassignedGuests]);

    useEffect(() => {
        if (selectedGuest !== null && selectedGuest.eventRegistrationId > -1 && selectedBed > -1) {
            setShowRoomTypes(true);            
        }
    }, [selectedBed, selectedGuest]);

    const selectBed = (bedId) => {
        setSelectedBed(bedId);
        setBedGridScrollPosition(document.getElementById("bedgriddiv").scrollTop);
    }
    
    function AssignGuest() {
      setAssigningGuest(true);
      const body = JSON.stringify({
        "EventRegistrationId" : selectedGuest.eventRegistrationId,
        "AssignmentType" : selectedGuest.fullName.includes("-Pre") ? "Early" : selectedGuest.fullName.includes("-Post") ? "Late" : "Standard",
        "BedId" : selectedBed,
        "RoomTypeId" : selectedGuest.age > 0 && selectedGuest.age < 18 ? -1 : selectedRoomType,
        "TestMode" : MODE === "TEST",
        "Requestor" : userInfo.userName
      });
        const url = SERVICE_URL + "/assignHousing";
        
            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
              setAssigningGuest(false);
              if (response.ok) {    
                setSelectedBed(-1);
                setSelectedRoomType(-1);
                setSelectedGuest(null);
                setShowRoomTypes(false);
                GetAssignedBeds(selectedEvent, "", -1);
                GetUnassignedGuests(selectedEvent);               
              } else {
                alert("A Problem Occurred");
              }              
            });
    }
      const { current: columns } = useRef([
              {
                  Header: "Building",
                  accessor: "buildingName"
              },
              {
                  Header: "Room Name",
                  accessor: "roomName"
              },
              {
                  Header: "Bed Type",
                  accessor: "bedType"
              },
              {
                Header: "Guest In Bed",
                accessor: "fullName"
              },
              {
                Header: "EventRegistrationId",
                accessor: "eventRegistrationId"
              }  
          ]
        ); 

    const eventSelect = events === undefined || events === null ? <div>No Valid Events Found</div> :
      <select width="75px" onChange={(event) => setSelectedEvent(event.target.value === "" ? null : parseInt(event.target.value))}> {
        events.map((event) => {
          return <option key={event.eventId} value={event.eventId}>{event.eventName}</option>
          })
        }
      </select>;
    
    const buildingSelect = buildings === null ? <div /> : <label>Building
        <select onChange={(event) => setSelectedBuilding(event.target.value)}> {
            buildings.map((building) => {
            return <option key={building.buildingId} value={building.buildingName}>{building.buildingName}</option>
            })
            }
        </select></label>;
    const roomTypeGroup = roomTypes === undefined || roomTypes === null ? <div>No Valid Room Types Found</div> :
        <form>{roomTypes.map((roomType) => { 
          return <div><label><input type="radio" name="roomType" value={roomType.roomTypeId} 
                onChange={(event) => setSelectedRoomType(parseInt(event.target.value))}/>{roomType.roomTypeName}</label></div>})
       } </form>;
    const aglabel = assigningGuest ? "Assigning" : "Assign Selected Housing";
    const roomTypeControl = !showRoomTypes || !selectedGuest || selectedGuest === null ? <div />
       : selectedGuest.age > 0 && selectedGuest.age < 18 ? (<div><button className='button' onClick={AssignGuest} disable={!assigningGuest}>{aglabel}</button></div>)
       : (<div>
            Select a Room Type
            {roomTypeGroup}
            <div>
              <button className='button' disabled={selectedRoomType < 1} onClick={AssignGuest} disable={!assigningGuest}>{aglabel}</button>
            </div>
          </div>);
    
    function Table({ columns, data, hiddenFields }) {
      // Use the state and functions returned from useTable to build your UI
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({
        columns,
        data,
        initialState : {
            hiddenColumns: hiddenFields
        }
      })
    
      // Render the UI for your table
      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              if (row.original.fullName === "" || (row.original.bedType === "Double" && !row.original.fullName.includes("&"))) {
                return (
                  <tr {...row.getRowProps()} onClick={() => selectBed(row.original.bedId)}>
                    {row.cells.map(cell => {
                      if (selectedBed === row.original.bedId) {
                        return <td style={{backgroundColor: "gray" }} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      } else {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      }
                        
                    })}
                  </tr>
                )
              } else {
                return (
                  <tr>
                    {row.cells.map(cell => {                      
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>                        
                    })}
                  </tr>
                )
              }              
            })}
          </tbody>
        </table>
      )
    }
    let bedTable = <div />;
    if (bedData !== null) {
        bedTable = <Table className="table" columns={columns} data={bedData} hiddenFields={["eventRegistrationId"]}/>; 
    } else {
        bedTable = <div>{bedResponseMsg}</div>
    }

    useEffect(() => {
      if (selectedGuest !== null) {
        if (!selectedEvent || selectedEvent === null) {
          setBedResponseMsg("");
          setSelectedGuest(null);
          setSelectedBed(null);
          setSelectedBuilding("All");
          setEvents(null);
          setBedData(null);
          setItems(null);
        } else {
          const event = events.filter(function (item) {
            return item.eventId === selectedEvent;
          });
          if (event[0].startDate !== selectedGuest.actualCheckIn || event[0].endDate !== selectedGuest.actualCheckOut) {
            GetAssignedBeds(selectedEvent, selectedGuest.fullName, selectedGuest.eventRegistrationId);
          }
        }                
      } else if (selectedEvent != null) {
        GetAssignedBeds(selectedEvent,"", -1);
      }      
    }, [selectedGuest, selectedEvent, userInfo.userName, GetAssignedBeds, events]);

    useEffect(() => {
        document.getElementById("bedgriddiv").scrollTop = bedGridScrollPosition; 
    }, [bedGridScrollPosition, bedData]);

    const toggleComplete = (eventReg) => {
        const bdrStart = eventReg.fullName.includes("-Post") ? eventReg.eventEnd : eventReg.actualCheckIn;
        const bdrEnd = eventReg.fullName.includes("-Pre") ? eventReg.eventStart : eventReg.actualCheckOut;
        setBedDateRange("Beds for " + new Date(bdrStart).toLocaleDateString('en-US') + "-" + new Date(bdrEnd).toLocaleDateString('en-US'));
        setSelectedGuest(eventReg);
    };

    return (
        <div style={{height: '40vh'}}>
            Note: If the event is not listed, ensure that the EventType and RateSchedule have been set (ie: not "Temporary") under Content Management!
            <div className="sideBySide">
              <div style={{width: '30%',height: '40vh'} }> 
              {eventSelect}<button className='button' onClick={GetEvents}>Refresh Events</button>               
                <div className="boxedContent">                    
                    <div className='item-list'>
                        {items !== null ? (items.map((item) => (
                            <div className='item-container'>
                                <div className='item-name' onClick={() => toggleComplete(item)}>
                                {selectedGuest !== null && item.eventRegistrationId === selectedGuest.eventRegistrationId && item.fullName === selectedGuest.fullName ? (
                                    <span className='highlighted-unbalanced'>{item.fullName}</span>
                                    ) : (       
                                    <span>{item.fullName}</span>
                                    )}
                                </div>
                            </div>
                        ))) : <div>{guestResponseMsg}</div>}
                    </div>                    
                </div>
                
              </div>             
              <div style={{width: '40%'} }>
                  {bedDateRange}&nbsp;&nbsp;&nbsp;
                  {buildingSelect}
                  <div id="bedgriddiv" style={{overflowY: 'scroll', scrollBehavior: 'auto', height: '30vh'}}>
                    <Styles>
                        {bedTable}
                    </Styles>
                  </div>                                    
              </div>
              <div>
                {roomTypeControl}
              </div>
            </div>
        </div>

    );
}