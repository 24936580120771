import React, { useState, useEffect, useCallback } from 'react';
import Calendar from 'react-calendar'
import '../../CustomCalendar.css';

export function LengthOfStay(props) {

    const { DateSelected, SelectedGuest, UpdateHousing } = props;
    const [theGuest] = useState(SelectedGuest);
    const [visibleDateRange, setVisibleDateRange] = useState([DateSelected,DateSelected]);
    const [dateRange, setDateRange] = useState([DateSelected,DateSelected]);
    const [daysAdded, setDaysAdded] = useState(0);


    const ResetRange = useCallback(() => {
        if (theGuest !== null) {
            let dr = [];
            let vdr = [];
            dr.push(new Date(theGuest.checkIn));
            vdr.push(new Date(theGuest.checkIn));
            dr.push(new Date(theGuest.checkOut));
            let newEnd = new Date(theGuest.checkOut);
            newEnd.setDate(newEnd.getDate()-1)
            vdr.push(newEnd);
            setDateRange(dr);
            setVisibleDateRange(vdr);
            console.log(dr);
            console.log(vdr);
        }
    }, [theGuest]);

    function AddToRange(type) {
        if (daysAdded < 0 || daysAdded < theGuest.bedDaysAvailable) {
            const newDays = daysAdded + 1;
            setDaysAdded(newDays);
            let newRange = [];
            let newVisibleRange = [];
            if (type === "start") {
                let newStart = dateRange[0];
                newStart.setDate(newStart.getDate()-1)
                newRange.push(newStart);
                newRange.push(dateRange[1]);
                setDateRange(newRange);

                newVisibleRange.push(newStart);
                newVisibleRange.push(visibleDateRange[1]);
                setVisibleDateRange(newVisibleRange);
            } else if (type === "end") {
                newRange.push(dateRange[0]);
                let newEnd = dateRange[1];
                newEnd.setDate(newEnd.getDate()+1)
                newRange.push(newEnd);
                setDateRange(newRange);

                newVisibleRange.push(visibleDateRange[0]);
                let newVisibleEnd = visibleDateRange[1];
                newVisibleEnd.setDate(newVisibleEnd.getDate()+1);
                newVisibleRange.push(newVisibleEnd);
                setVisibleDateRange(newVisibleRange);
            } else {
                alert("Unknown Add Detected!");
            }            
        } else {
            alert("No more days may be added to this stay.");
        }
    }

    function RemoveFromRange(type) {
        if (dateRange[0] === dateRange[1]) {
            alert("This is the last day of the stay! Mike, what should we do?");
        } else {
            const newDays = daysAdded -1;
            setDaysAdded(newDays);
            let newRange = [];
            let newVisibleRange = [];
            if (type === "start") {
                let newStart = dateRange[0];
                newStart.setDate(newStart.getDate()+1);
                newRange.push(newStart);
                newRange.push(dateRange[1]);
                setDateRange(newRange);

                newVisibleRange.push(newStart);
                newVisibleRange.push(visibleDateRange[1]);
                setVisibleDateRange(newVisibleRange);
            } else if (type === "end") {
                newRange.push(dateRange[0]);
                let newEnd = dateRange[1];
                newEnd.setDate(newEnd.getDate()-1)
                newRange.push(newEnd);
                setDateRange(newRange);

                newVisibleRange.push(visibleDateRange[0]);
                let newVisibleEnd = visibleDateRange[1];
                newVisibleEnd.setDate(newVisibleEnd.getDate()-1);
                newVisibleRange.push(newVisibleEnd);
                setVisibleDateRange(newVisibleRange);
            } else {
                alert("Unknown Remove Detected!");
            }            
        }
    }

    function SaveRange() {
        UpdateHousing(dateRange[0],dateRange[1],SelectedGuest.bedId, "Dates of Stay have been updated. Don't forget to update the Guest's rates!");
    }

    useEffect(() => {
        ResetRange();
    }, [theGuest, ResetRange]);

    const availableLabel = theGuest === null ? <div /> : <label>Bed is available for {theGuest.bedDaysAvailable} extra days</label>

    return (
        <div>
            {availableLabel}
            <table>
                <tr><td>
                <b>Start Date:</b>
                <button className='button' onClick={() => AddToRange("start")}>+</button>
                <button className='button' onClick={() => RemoveFromRange("start")}>-</button>
                </td>
                <td>
                <b>End Date:</b>
                <button className='button' onClick={() => AddToRange("end")}>+</button>
                <button className='button' onClick={() => RemoveFromRange("end")}>-</button>
                </td></tr>               
            </table>
            <Calendar className="react-calendar" enabled="false" value={visibleDateRange}/>            
            <div>
                <button className='button'n onClick={ResetRange}>Cancel Changes</button>
                <button className='button' onClick={SaveRange}>Save Dates</button>
            </div>
        </div>
    )
}