import React, { useState, useRef, useEffect } from 'react';
import {MODE, Styles, SERVICE_URL} from '../controls/Shared';

export default function CheckIn(props) {

    const [checkInData, setCheckInData] = useState(null);
    const [checkInDate, setCheckInDate] = useState(null);
    const [responseMsg, setResponseMsg] = useState("");
    const [ciGridScrollPosition, setCiGridScrollPosition] = useState(0);

    const { userInfo } = props;
    let checkInTable = <div />;
    let userMsg = <div />;

    function onScroll() {
        const scrollDiv = document.querySelector("#checkInDiv");
        if (scrollDiv !== null) {
            console.log(scrollDiv.offsetTop - window.scrollY);

        }
        
    }

    function GetCheckInData() {
        if (checkInDate === undefined || checkInDate === null) {
            alert("Please select a date first");
        } else {
            setCheckInData(null);
            setResponseMsg("Getting Check Ins...");
            const url = SERVICE_URL + "/getCheckIns";
            fetch(url, {
                method: 'POST',
                body: JSON.stringify({ "CheckInDate" : checkInDate, "TestMode" : MODE === "TEST", "Requestor" : userInfo.userName }),
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then((response) => {
                    if (response.ok) {
                      return response.json();
                    } else if (response.status === 406) {
                        setResponseMsg("No Data was found for the Request Date.");
                    } else {
                        setResponseMsg("A problem occurred with the data collection. Please see Event Logs.");
                    }
                })
                .then((responseJson) => {
                    if (responseJson != null) {
                        setResponseMsg("");
                        setCheckInData(responseJson);
                        document.getElementById("ciArea").scrollTop = ciGridScrollPosition;   
                    }                    
                });             
        }        
    }    

    const [inEditMode, setInEditMode] = useState({
        status: false,
        rowKey: null
    });

    const [notes, setNotes] = useState("");
    const [checkedIn, setCheckedIn] = useState(false);
    const [vaccineCard, setVaccineCard] = useState(false);
    const [negativePcrTest, setNegativePcrTest] = useState(false);
    const [parkingOnSite, setParkingOnSite] = useState(false);
    const [parkingPassNumber, setParkingPassNumber] = useState("");

    /**
     *
     * @param id
     * @param currentNotes
     */
    const onEdit = ({id, checkedIn, vaccineCard, negativePcrTest, currentNotes, parkingOnSite, parkingPassNumber}) => {
        setCiGridScrollPosition(document.getElementById("ciArea").scrollTop);
        setInEditMode({
            status: true,
            rowKey: id
        })
        setNotes(currentNotes);
        setCheckedIn(checkedIn);
        setVaccineCard(vaccineCard);
        setNegativePcrTest(negativePcrTest);
        setParkingOnSite(parkingOnSite);
        setParkingPassNumber(parkingPassNumber);
    }

    /**
     *
     */
    const updateInventory = (body) => {
        const url = SERVICE_URL + "/updateCheckIn";
        
            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then(json => {
                // reset inEditMode and unit price state values
                onCancel();
                // fetch the updated data
                GetCheckInData();
            })
    }

    /**
     *
     * @param id -The id of the product
     * @param newUnitPrice - The new unit price of the product
     */
    const onSave = ({id, checkedIn, vaccineCard, negativePcrTest, notes, parkingOnSite, parkingPassNumber}) => {
        const body = JSON.stringify({
            "EventRegistrationId" : id,
            "CheckedIn" : checkedIn,
            "VaccineCard" : vaccineCard,
            "NegativePcrTest" : negativePcrTest,
            "Notes" : notes === undefined ? "" : notes,
            "ParkingOnSite" : parkingOnSite,
            "ParkingPassNumber" : parkingPassNumber === undefined ? "" : parkingPassNumber,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
        });
        updateInventory(body);
    }

    const onCancel = () => {
        // reset the inEditMode state value
        setInEditMode({
            status: false,
            rowKey: null
        })
        // reset the unit price state value
        setNotes(notes);
    }

    const notesInputRef = useRef(null);
    const parkingInputRef = useRef(null);
    useEffect(() => {
        if (inEditMode.status) {
            notesInputRef.current.focus();
        }        
    }, [notes, inEditMode.status]);
    useEffect(() => {
        if (inEditMode.status) {
            parkingInputRef.current.focus();
        }        
    }, [parkingPassNumber, inEditMode.status]);


    if (checkInData !== null) {
        checkInTable = <table id="ciTable">
        <thead style={{position: "sticky"}}>
        <tr>
            <th></th>
            <th>Guest Name</th>
            <th>Event</th>
            <th>Arrival</th>
            <th>Departure</th>
            <th>Building</th>
            <th>Room</th>
            <th>Checked In</th>
            {/* <th>Vaccine Card</th> */}
            {/* <th>Negative PCR Test</th> */}
            <th>Notes</th>
            <th>Parking On Site</th>
            <th>Parking Pass Number</th>            
        </tr>
        </thead>
        <tbody>
        {
            checkInData.map((item) => (
                <tr key={item.eventRegistrationId}>
                    <td>
                        {
                            inEditMode.status && inEditMode.rowKey === item.eventRegistrationId ? (
                                <React.Fragment>
                                    <button
                                        className={"btn-success"}
                                        onClick={() => onSave({id: item.eventRegistrationId, checkedIn: checkedIn, 
                                            vaccineCard: vaccineCard, negativePcrTest: negativePcrTest, notes: notes,
                                        parkingOnSite: parkingOnSite, parkingPassNumber: parkingPassNumber})}
                                    >
                                        Save
                                    </button>

                                    <button
                                        className={"btn-secondary"}
                                        style={{marginLeft: 8}}
                                        onClick={() => onCancel()}
                                    >
                                        Cancel
                                    </button>
                                </React.Fragment>
                            ) : (
                                <button
                                    className={"btn-primary"}
                                    onClick={() => onEdit({id: item.eventRegistrationId, checkedIn: item.checkedIn, vaccineCard: item.vaccineCard,
                                        negativePcrTest: item.negativePcrTest, currentNotes: item.covidNotes, parkingOnSite: item.parkingOnSite, parkingPassNumber: item.parkingPassNumber})}
                                >
                                    Edit
                                </button>
                            )
                        }
                    </td>
                    <td>{item.guest}</td>
                    <td>{item.eventName}</td>
                    <td>{new Date(item.arrivalDate).toLocaleDateString('en-US')}</td>
                    <td>{new Date(item.departureDate).toLocaleDateString('en-US')}</td>
                    <td>{item.buildingName}</td>
                    <td>{item.roomNumber}</td>
                    <td>
                        {
                            inEditMode.status && inEditMode.rowKey === item.eventRegistrationId ? (
                                <input type="checkbox" 
                                    checked={checkedIn}
                                    onChange={(event) => setCheckedIn(event.target.checked)}
                                />
                            ) : (
                                <input type="checkbox" checked={item.checkedIn} readOnly="true"/>
                            )
                        }
                    </td>
{/*                     <td> {
                            inEditMode.status && inEditMode.rowKey === item.eventRegistrationId ? (
                                <input type="checkbox" 
                                    checked={vaccineCard}
                                    onChange={(event) => setVaccineCard(event.target.checked)}
                                />
                            ) : (
                                <input type="checkbox" checked={item.vaccineCard} readOnly="true"/>
                            )
                        }</td> */}
{/*                     <td> {
                            inEditMode.status && inEditMode.rowKey === item.eventRegistrationId ? (
                                <input type="checkbox" 
                                    checked={negativePcrTest}
                                    onChange={(event) => setNegativePcrTest(event.target.checked)}
                                />
                            ) : (
                                <input type="checkbox" checked={item.negativePcrTest} readOnly="true"/>
                            )
                        }</td> */}
                    <td>
                        {
                            inEditMode.status && inEditMode.rowKey === item.eventRegistrationId ? (
                                <input value={notes} ref={notesInputRef}
                                       onChange={(event) => setNotes(event.target.value)}
                                />
                            ) : (
                                item.covidNotes
                            )
                        }
                    </td>
                    <td> {
                            inEditMode.status && inEditMode.rowKey === item.eventRegistrationId ? (
                                <input type="checkbox" 
                                    checked={parkingOnSite}
                                    onChange={(event) => setParkingOnSite(event.target.checked)}
                                />
                            ) : (
                                <input type="checkbox" checked={item.parkingOnSite} readOnly="true"/>
                            )
                        }</td>
                    <td>{
                            inEditMode.status && inEditMode.rowKey === item.eventRegistrationId ? (
                                <input value={parkingPassNumber} ref={parkingInputRef}
                                       onChange={(event) => setParkingPassNumber(event.target.value)}
                                />
                            ) : (
                                item.parkingPassNumber
                            )
                        }</td>
                    
                </tr>
            ))
        }
        </tbody>
    </table>
    } else {
        userMsg = <div className="warning">{responseMsg}</div>;
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
      if (e.charCode === 13) {
        GetCheckInData();
      }
    };   

    return (
        
        <div>
            <input type="date" onChange={e => setCheckInDate(e.target.value)} onKeyPress={handleKeypress}/>
            <button className='button' onClick={GetCheckInData}>Apply Date</button>
            <div id="ciArea" className="ciContentArea">
                {userMsg}
                <Styles id="checkInDiv" onScrollCapture={() => onScroll()}>
                    {checkInTable}
                </Styles>
            </div>
        </div>
    )
}