import React from 'react'
import styled from 'styled-components';
import { useTable, useRowSelect } from 'react-table'

/**
 * Application Name
 */
export const APPLICATION = "oceanview";

/**
 * Local Testing
 */
export const DEVLOCAL = false;

/**
 * Mode
 */
export const MODE = "PROD";

/**
 *Data Year
 */
export const DataYear = new Date().getFullYear();

/**
 * Service URL
 */
export const SERVICE_URL = MODE === "PROD" ? "https://oceanic-edge-production.azurewebsites.net" : "https://oceanic-edge-test-f1-free.azurewebsites.net";
//export const SERVICE_URL = "https://localhost:44324";

/**
 * Table Styles
 */
export const Styles = styled.div`
padding: 1rem;

table {
  border-spacing: 0;
  border: 2px solid black;
  width:100%

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th {
    background-color: grey;
    position: sticky;
    top: 0;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
  }

  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
}
`

/**
 * Table Styles
 */
export const PrintStyles = styled.div`
padding: 1rem;

table {
  border-spacing: 0;
  border: 2px solid black;
  width:100%

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th {
    background-color: grey;
    position: static;
    top: 0;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
  }

  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
}
`

/**
 * Table Styles
 */
export const OdStyles = styled.div`
padding: 1rem;

table {
  border-spacing: 0;
  border: 2px solid black;
  max-width: 100%;
  max-height: 60vh;
  display: inline-block;
  overflow-x: auto;
  overflow-y: scroll;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th {
    background-color: grey;
    position: sticky;
    top: 0;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
  }

  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
}
`

export const ContentStyles = styled.div`
padding: 1rem;

table {
  border-spacing: 0;
  border: 2px solid black;
  max-height: 50vh;
  width: 100%;
  display: block;
  overflow-x: auto;
  overflow-y: scroll;
  white-space: nowrap;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th {
    background-color: grey;
    position: sticky;
    top: 0;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
  }

  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
}
`

/**
 * Table Styles
 */
 export const BillStyles = styled.div`
 padding: 1rem;
 
 table {
    width: 100%;
   tr {
    
   }
 
   th {
   }
 
   td {
   }
 }
 `

export function Table({ columns, data, hiddenFields }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
      initialState : {
          hiddenColumns: hiddenFields
      }
    })
  
    // Render the UI for your table
    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
            <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef
  
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  export function SelectableTable({ columns, data, hiddenFields, setSelection, confirmedBatches }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows
    } = useTable(
      {
        columns,
        data,
        initialState : {
          hiddenColumns: hiddenFields
      } 
      },
      useRowSelect,
      hooks => {
        hooks.visibleColumns.push(columns => [
          ...columns,
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          }            
        ])
      }
    )
    
    React.useEffect(() => {
      // Bubble up the selection to the parent component
      if (selectedFlatRows.length !== confirmedBatches.length) {
        let selections = [];
        selectedFlatRows.forEach((select) => {
          selections.push(select.values);
        });
        setSelection(selections); 
      } 
    }, [selectedFlatRows, confirmedBatches, setSelection]);
    // Render the UI for your table
    return (
      <>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }

  

 