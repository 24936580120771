import React, { useState, useCallback, useEffect, useRef } from 'react';
import { MODE, SERVICE_URL, Styles, Table, DataYear } from '../../controls/Shared';

export default function EventLogs(props) {

    const { userInfo } = props;

    const [years, setYears] = useState([]);
    const currentYear = DataYear;
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [searchText, setSearchText] = useState("");
    const [logData, setLogData] = useState(null);
    const [responseMsg, setResponseMsg] = useState("");

    const { current: columns } = useRef([
            {
                Header: "Event Log Id",
                accessor: "eventLogId"
            },
            {
                Header: "Event Date",
                accessor: "eventDate"
            },
            {
                Header: "Event Source",
                accessor: "eventSource"
            },
            {
                Header: "Event Details",
                accessor: "eventDetails"
            }

        ]
      );  
  const getLogData = useCallback(() => {
    const url = SERVICE_URL + "/getEventLogs";

    const body = JSON.stringify({
      "Year" : selectedYear,
      "SearchText" : searchText,
      "TestMode" : MODE === "TEST",
      "Requestor" : userInfo.userName
    });
    
    fetch(url, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 406) {
            setResponseMsg("No Event Logs Found.");
          } else {
            setResponseMsg("There was a problem with the request. See an application developer.");
          }          
          return null;
        }
    })
    .then((responseJson) => {
        if (responseJson !== null) setResponseMsg("");
        setLogData(responseJson);
    });  
  }, [selectedYear, searchText, userInfo.userName]);

    useEffect(() => {
      if (years.length === 0) {        
        const filledYears = [];
        for (var i = currentYear; i >= currentYear - 10; i--) {
          filledYears.push(i);
        }
        setYears(filledYears);
      }      
    }, [years.length, currentYear]);    

    const yearSelect = years === undefined ? <div /> : 
    <select onChange={(event) => setSelectedYear(parseInt(event.target.value))}> {
        years.map((year) => {
        return <option key={year} value={year}>{year}</option>
        })
        }
    </select>;

    let logTable = <div />;
    let userMsg = <div />;
    if (logData !== null) {
        logTable = <Table className="table" columns={columns} data={logData} hiddenFields={[]}/>; 
    } else {
        userMsg = <div className="warning">{responseMsg}</div>;
    }

    return (
        <div className="checkin-content-tabbed">
            <label>Event Year</label>
            {yearSelect}
            <label>Search Text</label>
            <input type="text" value={searchText} onChange={(event) => setSearchText(event.target.value)}/>
            <button className='button' onClick={() => getLogData()}>Lookup Event Logs</button>
            <div>
                {userMsg}              
                <Styles>
                    {logTable}
                </Styles>
            </div>
        </div>
    )
}