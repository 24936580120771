import React from 'react';
import AssignHousing from './AdminPages/AssignHousing';
import EventManagement from './AdminPages/EventManagement';
import ContentManagement from './AdminPages/ContentManagement';
import Import from './AdminPages/Import';
import PartyManagement from './AdminPages/PartyManagement';
import RateManagement from './AdminPages/RateManagement';
import Reporting from './AdminPages/Reporting';
import EventLogs from './AdminPages/EventLogs';
import Users from './AdminPages/Users';
import PosAdmin from './AdminPages/PosAdmin';

export default function Admin(props) {

    const { paymentMethods, billableItemCategories, userInfo, buildings, roomTypes } = props;

    const data = [
        {id : '1',
         tabTitle: "Content Management",
         tabContent: <ContentManagement userInfo={userInfo}/>
        },
        {id : '2',
         tabTitle: "Import Data",
         tabContent: <Import userInfo={userInfo}/>
        },
        {id : '3',
         tabTitle: "Assign Housing",
         tabContent: <AssignHousing userInfo={userInfo} buildings={buildings} roomTypes={roomTypes}/>
        },
        {id : '4',
         tabTitle: "Event Management",
         tabContent: <EventManagement userInfo={userInfo} />
        },
        {id : '5',
         tabTitle: "Party Management",
         tabContent: <PartyManagement userInfo={userInfo}/>
        },
        {id : '6',
          tabTitle: "Rate Management",
          tabContent: <RateManagement userInfo={userInfo} roomTypes={roomTypes}/>
        },
        {id : '7',
         tabTitle: "Point of Sales",
         tabContent: <PosAdmin userInfo={userInfo}/>
        },
        {id : '8',
         tabTitle: "Reporting",
         tabContent: <Reporting paymentMethods={paymentMethods} billableItemCategories={billableItemCategories} userInfo={userInfo}/>
        },
        {
          id: '9',
          tabTitle: "Event Logs",
          tabContent: <EventLogs userInfo={userInfo}/>
        },
        {
          id: '10',
          tabTitle: "Users",
          tabContent: <Users userInfo={userInfo}/>
        }       
      ]
      
      function Tab(props){
        
        const [visibleTab, setVisibleTab] = React.useState(props.data[0].id)
      
        const listTitles = props.data.map((item) => 
            <li onClick={() => setVisibleTab(item.id)} className={visibleTab === item.id ? "tab-title tab-title--active" : "tab-title"}>{item.tabTitle}</li>
        )       
                                         
        const listContent = props.data.map((item) => 
            <p style={visibleTab === item.id ? {} : {display: 'none'}}>{item.tabContent}</p>
        )
        
        return(
            <div className="tabs">
              <ul className="tabs-titles">
                {listTitles}
              </ul>
              <div className="tab-content">
                 {listContent}
              </div>
            </div>
          )
      }
    
    return (
        <div className="checkin-content-tabbed">
            <Tab data={data}/>
        </div>
    );
  
}