import React, { useState, useCallback, useMemo } from 'react';
import { useEffect } from 'react';
import { MODE, SERVICE_URL, Styles, Table, DataYear } from '../../controls/Shared';
import Popup from '../../controls/Popup';

export default function ContentManagement(props) {

    const { userInfo } = props;
    const [years, setYears ] = useState([]);
    const currentYear = DataYear;
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedTable, setSelectedTable] = useState("");
    const [tableContent, setTableContent] = useState(null);
    const [subTableContent, setSubTableContent] = useState(null);
    const [displayContent, setDisplayContent] = useState(null);
    const [editFormContent, setEditFormContent] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState(<div />);  
    const [responseMsg, setResponseMsg] = useState("");

    function ClosePopup() {
        setSubTableContent(null);
        setShowPopup(false);
    }

    const checkBoxFields = useMemo(() => ["Active", "IsVisible", "CotEligibility", "CribEligibility", "HandicapAccessible",
            "HasOutlets", "LockingDoor"],[]);

    const GetTableData = useCallback((table, subTable) => {
        if (table === "") {
            alert("Please select a table");
        } else {
            if (!subTable) setDisplayContent(<div />);
            const subArray = table.split("|");
            const tableName = subTable ? subArray[0].toUpperCase().replace("ID", "") : subArray[0];
            const subIdValue = subArray[1];
            setResponseMsg("Getting Table Data...");
            const body = JSON.stringify({
                "TableName" : tableName,
                "IdValue" : subTable && subIdValue? parseInt(subIdValue) : -1,
                "Year" : selectedYear,
                "TestMode" : MODE === "TEST",
                "Requestor" : userInfo.userName
                });

            const url = SERVICE_URL + "/getTableContent";
            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then((response) => {
                if (response.ok) {                
                    return response.json();
                } else {
                    setResponseMsg("No Data found");
                    return null;
                }
            })
            .then((responseJson) => {
                //if (responseJson !== null) setResponseMsg("");
                if (!subTable) {
                    setResponseMsg("");
                    setTableContent(responseJson);
                } else {
                    setResponseMsg("");
                    setSubTableContent(responseJson);
                }
            });
        }
        
    }, [userInfo.userName, selectedYear]);
    
    const deleteEntry = useCallback((values) => {
        if (window.confirm("Are you sure you want to delete this entry?")) {
            const body = JSON.stringify({
                "TableName" : selectedTable,
                "TableContent" : values,
                "TestMode" : MODE === "TEST",
                "Requestor" : userInfo.userName
                });
    
                const url = SERVICE_URL + "/deleteTableContent";
            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then((response) => {
                if (response.ok) {
                    GetTableData(selectedTable, false);
                } else {
                    alert("An error occured with the delete. Please see the Event Logs for more detail");
                }
            })
        }
    }, [GetTableData, selectedTable, userInfo.userName]);

    const DisplayContentTable = useCallback(() => {
        setEditFormContent(null);
        if (tableContent !== null && tableContent.length > 0) {
            const contentColumns = Object.keys(tableContent[0]).map((key, id)=>{
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => value === null ? "" : String(value)
                }
              })
            contentColumns.unshift({
            width: 300,
            Header: "",
            accessor: "delete",
            Cell: row => {
                return (
                <button onClick={() => deleteEntry(row.row.values)}>
                Delete
                </button>);
            }
            });
            contentColumns.unshift({
                width: 300,
                Header: "",
                accessor: "edit",
                Cell: row => {
                    return (
                  <button onClick={() => openEditForm(row.row.values)}>
                    Edit
                  </button>);
                }
              });
              
            setDisplayContent(<Styles><Table className="table" columns={contentColumns} data={tableContent} hiddenFields={[]}/></Styles>);
        } else {
            setDisplayContent(<div />);
        }
    }, [tableContent, deleteEntry]);

    

    const SubmitContent = useCallback(() => {
        const body = JSON.stringify({
            "TableName" : selectedTable,
            "TableContent" : editFormContent,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/updateTableContent";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
                GetTableData(selectedTable, false);
            } else {
              alert("An error occured with the update. Please see the Event Logs for more detail");
            }
        })
    }, [editFormContent, selectedTable, GetTableData, userInfo.userName]);

    function openEditForm(values) {        
        setEditFormContent(values);        
    };

    

    function OpenEmptyForm() {
        if (selectedTable === "") {
            alert("Please select a table!");
        } else {
            let newContent = JSON.parse(JSON.stringify(tableContent[0]));
            for (var key in newContent) {
                if (key === "ID") {
                    newContent[key] = -1;
                } else {
                    newContent[key] = "";
                }            
            }
            openEditForm(newContent);
        }        
    }

    const updateContent = useCallback((e, name) => {
        let updated = {};
        for (var key in editFormContent) {
            if (key !== "edit" && key !== "delete") {
                if (key !== name) {
                    updated[key] = editFormContent[key];
                } else {
                    if (checkBoxFields.includes(key)) {
                        updated[key] = e.target.checked;
                    } else {
                        updated[key] = e.target.value;
                    }                
                }
            }            
        }
        setEditFormContent(updated);
    }, [editFormContent, checkBoxFields]);

    useEffect(() => {
        if (years.length === 0) {        
          const filledYears = [];
          for (var i = currentYear; i >= currentYear - 4; i--) {
            filledYears.push(i);
          }
          setYears(filledYears);
        }      
      }, [years.length, currentYear, setYears]);

    useEffect(() => {
        if (subTableContent !== null && subTableContent.length > 0) {
            const contentColumns = Object.keys(subTableContent[0]).map((key, id)=>{
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => value === null ? "" : String(value)
                }
              });
            setPopupContent(<Styles><Table className="table" columns={contentColumns} data={subTableContent} hiddenFields={[]}/></Styles>);
            setShowPopup(true);
        }
    }, [subTableContent])

    useEffect(() => {
        if (editFormContent !== null) {
            let fields = [];
            for (var key in editFormContent) {
                if (key !== "edit" && key !== "delete") {
                    if (key !== "ID") {
                        const name = key;
                        const labelField = key.toUpperCase().endsWith("ID") ? <text onClick={() => GetTableData(name+"|"+editFormContent[name], true)}>{name}</text> : <label>{name}: </label>;
                        let field = <div />;
                        if (checkBoxFields.includes(key)) {
                            field = <div>{labelField}<input id={name} type="checkbox" checked={editFormContent[name]} onChange={(e) => updateContent(e, name)}/></div>;
                        } else {
                            if (selectedTable === "printDetails" && (name === "PrintHeader" || name === "PrintFooter")) {
                                field = <div>{labelField} <textarea id={name} rows="5" cols="100" value={editFormContent[name]} onChange={(e) => updateContent(e, name)}/></div>;                            
                            } else {
                                field = <div>{labelField} <input id={name} type="text" value={editFormContent[name]} onChange={(e) => updateContent(e, name)}/></div>;                            
                            }
                        }
                        fields.push(field);
                    } else {
                        const field = <div><label>{key}: {editFormContent[key]} </label></div>;
                        fields.push(field);
                    }                    
                }            
            }
            const form = <div><label><b>Click on reference table field names (...ID) to see sub table</b></label>{fields}<button className='button' onClick={SubmitContent}>Submit</button><button className='button' onClick={DisplayContentTable}>Cancel</button></div>;
            setDisplayContent(form);
        }

    }, [editFormContent, DisplayContentTable, SubmitContent, updateContent, checkBoxFields, GetTableData, selectedTable]);    

    

    useEffect(() => {
        DisplayContentTable();
    }, [tableContent, DisplayContentTable]);

    const yearSelect = years === undefined ? <div /> : 
    <select onChange={(event) => setSelectedYear(parseInt(event.target.value))}> {
        years.map((year) => {
        return <option key={year} value={year}>{year}</option>
        })
        }
    </select>;

    
    const popup = showPopup ? <Popup closePopup={ClosePopup} enablePrint={false}>{popupContent}</Popup> : undefined;
    return (
        <div>
            <div>
                Select A Table
                <select name="tables" id="tables" onChange={(event) => setSelectedTable(event.target.value)}>
                    <option value=""></option>
                    <option value="batch">Batch</option>
                    <option value="batchedPayment">Batched Payment</option>
                    <option value="bed">Bed</option>
                    <option value="bedtype">Bed Type</option>
                    <option value="billableItem">Billable Item</option>
                    <option value="billableItemCategory">Billable Item Category</option>
                    <option value="building">Building</option>
                    <option value="charge">Charges</option>
                    <option value="deposits">Deposits</option>
                    <option value="dateRange">Date Range</option>
                    <option value="deletedCharge">Deleted Charges</option>
                    <option value="deletedPayment">Deleted Payments</option>
                    <option value="event">Event</option>
                    <option value="eventRegistration">Event Registration</option>
                    <option value="eventType">Event Type</option>
                    <option value="fund">Fund</option>
                    <option value="housingAssignment">Housing Assignment</option>
                    <option value="partyMembers">Party Members</option>
                    <option value="payment">Payment</option>
                    <option value="paymentMethod">Payment Method</option>
                    <option value="printDetails">Print Detail</option>
                    <option value="room">Room</option>
                    <option value="roomType">Room Type</option>
                    <option value="unit">Units</option>               
                </select>
                {yearSelect}
                <button className='button' onClick={() => GetTableData(selectedTable, false)}>Get Data</button>
                <div>
                    <button className='button' onClick={() => OpenEmptyForm()}>Add New Entry</button>
                </div>
                <div className="contentArea">
                    {responseMsg}
                    {displayContent}
                </div>            
            </div>
            {popup}
        </div>
        
    )
}