import React, {useCallback, useEffect, useState} from 'react';
import CheckIn from './CheckIn';
import Transactions from './Transactions';
import Admin from './Admin';
import GuestInfo from './GuestInfo';
import OccupancyDisplay from './OccupancyDisplay';
import Reporting from './Reporting';
import {MODE, SERVICE_URL} from '../controls/Shared';

export default function OVHome(props) { 

    const {showHome, setShowHome, userInfo} = props;

    const [paymentMethods, setPaymentMethods] = useState(null);
    const [billableItemCategories, setBillableItemCategories] = useState(null);
    const [allBillableItems, setAllBillableItems] = useState(null);
    const [buildings, setBuildings] = useState(null);
    const [roomTypes, setRoomTypes] = useState(null);
    const welcomeMessage = "WELCOME"

    const GetPaymentMethods = useCallback((cats, items) => {
        const url = SERVICE_URL + "/getPaymentMethods";
        fetch(url, {
          method: 'POST',
          body: JSON.stringify({ "TestMode" : MODE === "TEST", "Requestor" : userInfo.userName }),
          headers: {
              'Content-Type': 'application/json'
          }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 406) {
                //setResponseMsg("No Events found.");
              } else {
                //setResponseMsg("There was a problem with the request for Event data.");
              }
              
              return null;
            }
        })
        .then((responseJson) => {
            //if (responseJson !== null) setResponseMsg("");
            setBillableItemCategories(cats)
            setAllBillableItems(items);
            setPaymentMethods(responseJson);
        });
    }, [userInfo.userName]);

    const GetBillableItems = useCallback((cats) => {
        const url = SERVICE_URL + "/getBillableItems";
        fetch(url, {
          method: 'POST',
          body: JSON.stringify({ "TestMode" : MODE === "TEST", "Requestor" : userInfo.userName }),
          headers: {
              'Content-Type': 'application/json'
          }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 406) {
                //setResponseMsg("No Events found.");
              } else {
                //setResponseMsg("There was a problem with the request for Event data.");
              }
              
              return null;
            }
        })
        .then((responseJson) => {
            //if (responseJson !== null) setResponseMsg("");
            GetPaymentMethods(cats, responseJson);
        });
    }, [GetPaymentMethods, userInfo.userName]);

    

    const GetBillableItemCategories = useCallback(() => {
        const url = SERVICE_URL + "/getCategories";
        fetch(url, {
          method: 'POST',
          body: JSON.stringify({ "TestMode" : MODE === "TEST", "Requestor" : userInfo.userName }),
          headers: {
              'Content-Type': 'application/json'
          }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 406) {
                //setResponseMsg("No Events found.");
              } else {
                //setResponseMsg("There was a problem with the request for Event data.");
              }
              
              return null;
            }
        })
        .then((responseJson) => {
            //if (responseJson !== null) setResponseMsg("");
            GetBillableItems(responseJson);
        });
    },[GetBillableItems, userInfo.userName]);

    const GetRoomTypes = useCallback(() => {
      const body = JSON.stringify({
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });

        const url = SERVICE_URL + "/getRoomTypes";
      fetch(url, {
          method: 'POST',
          body: body,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return null;
          }
      })
      .then((responseJson) => {
          //if (responseJson !== null) setResponseMsg("");
          setRoomTypes(responseJson);
      });
  }, [userInfo.userName]);

    const GetBuildings = useCallback(() => {        
        const url = SERVICE_URL + "/getBuildings";
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({ "TestMode" : MODE === "TEST", "Requestor" : userInfo.userName }),
            headers: {
                'Content-Type': 'application/json'
            }
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong');
                }
            })
            .then((responseJson) => {
                setBuildings(responseJson);
            })
            .catch((error) => {
                //setResponseMsg("No Data was found for the Request Date.");
            });      
    }, [userInfo.userName]);


    const data = [
        {id : '1',
         tabTitle: "Dock Check-In",
         tabContent: <CheckIn userInfo={userInfo}/>
        },
        {id : '2',
         tabTitle: "Occupancy Display",
         tabContent: <OccupancyDisplay buildings={buildings} userInfo={userInfo}/>
        },
        {id : '3',
         tabTitle: "Guest Info",
         tabContent: <GuestInfo userInfo={userInfo}/>
        },
        {id : '4',
         tabTitle: "Transactions",
         tabContent: <Transactions paymentMethods={paymentMethods} allBillableItems={allBillableItems} billableItemCategories={billableItemCategories} userInfo={userInfo}/>
        },
        {id : '5',
         tabTitle: "Reporting",
         tabContent: <Reporting userInfo={userInfo}/>
        }         
      ]

      if (userInfo.isEdgeAdmin) {
        data.push({id : '6',
        tabTitle: "Administration",
        tabContent: <Admin paymentMethods={paymentMethods} billableItemCategories={billableItemCategories} userInfo={userInfo} buildings={buildings} roomTypes={roomTypes}/>
       });
      }
      
      function Tab(props){      
        const [visibleTab, setVisibleTab] = useState(0);
     
        const listTitles = props.data.map((item) => 
            <li key={item.id} onClick={() => setVisibleTab(item.id)} className={visibleTab === item.id ? "tab-title tab-title--active" : "tab-title"}>{item.tabTitle}</li>
        )       
                                         
        const listContent = props.data.map((item) => 
            <p style={visibleTab === item.id ? {} : {display: 'none'}}>{item.tabContent}</p>
        )

        const adminIcon = userInfo.isEdgeAdmin ?  <td style={{textAlign: "center", fontFamily: "Gabriola", fontSize: "150%"}}><figure>
        <img alt="Icon Missing" className="clickableArea" src="https://img.icons8.com/nolan/96/data-center.png" title="Permission restricted access to Content Management, Import Data, Assign Housing, Party and Volunteer Management and Reporting" onClick={() => setVisibleTab('6')}/><figcaption style={{fontFamily: "Pompiere"}}>Administration</figcaption></figure></td> : <td />;

        const iconMenu = <div>
              <table style={{marginLeft: "auto", marginRight: "auto", width:"100%", backgroundColor: 'oldlace', border: 'solid'}} >
                  <tbody>
                  <tr>
                      <td style={{textAlign: "center", fontFamily: "Gabriola", fontSize: "150%"}}><figure>
                          <img alt="Icon Missing" className="clickableArea" src="https://img.icons8.com/fluent/96/000000/hotel-check-in.png" title="Interface for checking in guests and adding parking info" onClick={() => setVisibleTab('1')}/><figcaption style={{fontFamily: "Pompiere"}}>Dock Check-In</figcaption></figure></td>
                      <td style={{textAlign: "center", fontFamily: "Gabriola", fontSize: "150%"}}><figure>
                          <img alt="Icon Missing" className="clickableArea" src="https://img.icons8.com/officel/80/000000/hotel-information.png" title="Main interface grid for occupants by building, room and date" onClick={() => setVisibleTab('2')}/><figcaption style={{fontFamily: "Pompiere"}}>Occupancy Display</figcaption></figure></td>
                      <td style={{textAlign: "center", fontFamily: "Gabriola", fontSize: "150%"}}><figure>
                          <img alt="Icon Missing" className="clickableArea" src="https://img.icons8.com/officel/80/000000/select-users.png" title="User Administration including Add, Delete, Lock and Unlock" onClick={() => setVisibleTab('3')}/><figcaption style={{fontFamily: "Pompiere"}}>Guest Info</figcaption></figure></td>
                      
                  </tr>
                  <tr>
                      <td style={{textAlign: "center", fontFamily: "Gabriola", fontSize: "150%"}}><figure>
                          <img alt="Icon Missing" className="clickableArea" src="https://img.icons8.com/nolan/96/billing-machine.png" title="Access to View Bills, Add Charges/Payments, POS, Batch Open Drawer and Transaction Reports" onClick={() => setVisibleTab('4')}/><figcaption style={{fontFamily: "Pompiere"}}>Transactions</figcaption></figure></td>
                      {adminIcon}
                      <td style={{textAlign: "center", fontFamily: "Gabriola", fontSize: "150%"}}><figure>
                          <img alt="Icon Missing" className="clickableArea" src="https://img.icons8.com/external-soft-fill-juicy-fish/60/external-reporting-business-management-soft-fill-soft-fill-juicy-fish.png" title="Reporting" onClick={() => setVisibleTab('5')}/><figcaption style={{fontFamily: "Pompiere"}}>Reporting</figcaption></figure></td>
                      <td />                                        
                  </tr>
              </tbody>
              </table> <br /></div> ;

        const contentDisplay = visibleTab === 0 || showHome ? iconMenu : 
                <div className="tabs">
                <ul className="tabs-titles">
                {listTitles}
                </ul>
                <div className="tab-content">{listContent}</div>
                </div>;
        setShowHome(false);
        return(
            <div className="iconMenu">
                {contentDisplay}
            </div>            
          )
      }

    useEffect(() => {
        if (buildings === null) {
            GetBuildings();
        }
        if (billableItemCategories === null) {
            GetBillableItemCategories();
        }
        if (roomTypes === null) {
           GetRoomTypes();
        }
    }, [buildings, GetBuildings, billableItemCategories, GetBillableItemCategories, GetRoomTypes, roomTypes]);
    
    return (
      (userInfo && userInfo !== null) ?
        userInfo.isEdgeUser ? 
          (buildings !== null && billableItemCategories !== null && allBillableItems !== null && paymentMethods !== null) ?
              <div>
              <Tab data={data}/>            
              </div> 
          : 
              <div>
                <div><h2 style={{fontSize: "300%", fontFamily: "Pompiere", textAlign: "center"}}>{welcomeMessage}</h2></div>
                <div style={{textAlign: "center"}}>Please wait while we load up your data...</div>
              </div>   
        : <div><div><h2 style={{fontSize: "300%", fontFamily: "Pompiere", textAlign: "center"}}>{welcomeMessage}</h2></div>
        <div style={{textAlign: "center"}}>You do not have permissions to access this site.</div></div>
        
    
      : <div><div><h2 style={{fontSize: "300%", fontFamily: "Pompiere", textAlign: "center"}}>{welcomeMessage}</h2></div>
      <div style={{textAlign: "center"}}>Please Log In</div></div>
    );       
  
}