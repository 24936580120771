import React from 'react';
import { ChargePanel } from '../../controls/ChargePanel';

export default function PointOfSale(props) {

    const { paymentMethods, allBillableItems, billableItemCategories, userInfo } = props;

    return (
        <ChargePanel pointOfSale={true} eventRegistrationId={-1} paymentMethods={paymentMethods} 
            allBillableItems={allBillableItems} billableItemCategories={billableItemCategories} userInfo={userInfo} />
    )
}