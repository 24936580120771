import React, { useState, useCallback, useEffect, useRef } from 'react';
import { MODE, SERVICE_URL, Styles } from '../../controls/Shared';

export default function Users(props) {

    const { userInfo } = props;

    const [inEditMode, setInEditMode] = useState({
        status: false,
        rowKey: null
    });

    const [users, setUsers] = useState(null);
    const [responseMsg, setResponseMsg] = useState("");
    const [showAdd, setShowAdd] = useState(false);
    const [userName, setUserName] = useState("");
    const [userLogin, setUserLogin] = useState("");
    const [isEdgeUser, setIsEdgeUser] = useState(false);
    const [isEdgeAdmin, setIsEdgeAdmin] = useState(false);
    const [newAzureGuid, setNewAzureGuid] = useState("");
    const [newUserLogin, setNewUserLogin] = useState("");
    const [newUserName, setNewUserName] = useState("");
    const [newIsEdgeUser, setNewIsEdgeUser] = useState(false);
    const [newIsEdgeAdmin, setNewIsEdgeAdmin] = useState(false);

    const onEdit = ({id, userName, userLogin, isEdgeUser, isEdgeAdmin }) => {
        setInEditMode({
            status: true,
            rowKey: id
        })
        setUserName(userName);
        setUserLogin(userLogin);
        setIsEdgeUser(isEdgeUser);
        setIsEdgeAdmin(isEdgeAdmin);
    }

    const updateUser = (body) => {
        const url = SERVICE_URL + "/saveUser";
        
            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then(json => {
                // reset inEditMode and unit price state values
                onCancel();
                
                CloseAdd();   
                // fetch the updated data
                getUserData();
            })
    }

    const onSave = ({id, azureGuid, userLogin, userName, isEdgeUser, isEdgeAdmin}) => {
        
        const body = JSON.stringify({
            "UserId" : id,
            "AzureGuid" : azureGuid,
            "UserLogin" : userLogin,
            "UserName" : userName,
            "IsEdgeUser" : isEdgeUser,
            "IsEdgeAdmin" : isEdgeAdmin,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
        });
        updateUser(body);
       
    }

    const onCancel = () => {
        // reset the inEditMode state value
        setInEditMode({
            status: false,
            rowKey: null
        })
        // reset the unit price state value
        setUserName(userName);
        setUserLogin(userLogin);
    }

    const userNameInputRef = useRef(null);
    const userLoginInputRef = useRef(null);

    useEffect(() => {
        if (inEditMode.status) {
            userNameInputRef.current.focus();
        }        
    }, [userName, inEditMode.status]);    

    useEffect(() => {
        if (inEditMode.status) {
            userLoginInputRef.current.focus();
        }        
    }, [userLogin, inEditMode.status]);

    
    const getUserData = useCallback(() => {
        const url = SERVICE_URL + "/getAllUsers";

        const body = JSON.stringify({
            "UserId" : -1,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
        });

        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
            'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
            if (response.status === 406) {
                setResponseMsg("No Users Found.");
            } else {
                setResponseMsg("There was a problem with the request. See Event Logs.");
            }          
            return null;
            }
        })
        .then((responseJson) => {
            if (responseJson !== null) setResponseMsg("");
            setUsers(responseJson);
        });  
    }, [userInfo.userName]);

    useEffect(() => {
        getUserData();
    },[getUserData])

    function CloseAdd() {
        setNewAzureGuid("");
        setNewUserLogin("");
        setNewUserName("");
        setNewIsEdgeUser(false);
        setNewIsEdgeAdmin(false);
        setShowAdd(false);
    }

    function AddNewUser() {
        if (newUserLogin === "") {
            alert("Please enter an Email for this user!");
        } else if (users.some((user) => { return user.userLogin=== newUserLogin; })) {
            alert("This Email has already been added!");
        } else {
            const body = JSON.stringify({
                "UserId" : -1,
                "AzureGuid" : newAzureGuid,
                "UserName" : newUserName,
                "UserLogin" : newUserLogin,
                "IsEdgeUser" : newIsEdgeUser,
                "IsEdgeAdmin" : newIsEdgeAdmin,
                "TestMode" : MODE === "TEST",
                "Requestor" : userInfo.userName
            });

            updateUser(body);
        }
    }


    let userTable = <div />;
    let userMsg = <div />;
    if (users !== null) {
        userTable = <table>
        <thead style={{position: "sticky"}}>
        <tr>
            <th></th>
            <th>User ID</th>
            <th>Azure GUID</th>
            <th>User Login</th>
            <th>User Name</th>
            <th>Is Edge User</th>
            <th>Is Edge Admin</th>
            <th>Last Updated</th>
            <th>Last Updated By</th>           
        </tr>
        </thead>
        <tbody>
        {
            users.map((item) => (
                <tr key={item.userId}>
                    <td>
                        {
                            inEditMode.status && inEditMode.rowKey === item.userId ? (
                                <React.Fragment>
                                    <button
                                        className={"btn-success"}
                                        onClick={() => onSave({id: item.userId, azureGuid: item.azureGuid, userLogin: item.userLogin, userName: userName, 
                                            isEdgeUser: isEdgeUser, isEdgeAdmin: isEdgeAdmin})}
                                    >
                                        Save
                                    </button>

                                    <button
                                        className={"btn-secondary"}
                                        style={{marginLeft: 8}}
                                        onClick={() => onCancel()}
                                    >
                                        Cancel
                                    </button>
                                </React.Fragment>
                            ) : (
                                <button
                                    className={"btn-primary"}
                                    onClick={() => onEdit({id: item.userId, userName: item.userName,
                                        userLogin: item.userLogin, 
                                        isEdgeUser: item.isEdgeUser, isEdgeAdmin: item.isEdgeAdmin})}
                                >
                                    Edit
                                </button>
                            )
                        }
                    </td>
                    <td>{item.userId}</td>
                    <td>{item.azureGuid}</td>
                    <td>
                        {
                            inEditMode.status && inEditMode.rowKey === item.userId ? (
                                <input value={userLogin} ref={userLoginInputRef}
                                       onChange={(event) => setUserLogin(event.target.value)}
                                />
                            ) : (
                                item.userLogin
                            )
                        }
                    </td>
                    <td>
                        {
                            inEditMode.status && inEditMode.rowKey === item.userId ? (
                                <input value={userName} ref={userNameInputRef}
                                       onChange={(event) => setUserName(event.target.value)}
                                />
                            ) : (
                                item.userName
                            )
                        }
                    </td>
                    <td>
                        {
                            inEditMode.status && inEditMode.rowKey === item.userId ? (
                                <input type="checkbox" 
                                    checked={isEdgeUser}
                                    onChange={(event) => setIsEdgeUser(event.target.checked)}
                                />
                            ) : (
                                <input type="checkbox" checked={item.isEdgeUser} readOnly={true}/>
                            )
                        }
                    </td>
                    <td>
                        {
                            inEditMode.status && inEditMode.rowKey === item.userId ? (
                                <input type="checkbox" 
                                    checked={isEdgeAdmin}
                                    onChange={(event) => setIsEdgeAdmin(event.target.checked)}
                                />
                            ) : (
                                <input type="checkbox" checked={item.isEdgeAdmin} readOnly={true}/>
                            )
                        }
                    </td>
                    <td>{new Date(item.updated).toLocaleDateString('en-US')}</td>
                    <td>{item.updatedBy}</td>                    
                </tr>
            ))
        }
        </tbody>
    </table>; 
    } else {
        userMsg = <div className="warning">{responseMsg}</div>;
    }
    const addForm = !showAdd ? <button className='button' onClick={() => setShowAdd(true)}>Add User</button> :
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>Azure Guid</td>
                            <td><input type="text" value={newAzureGuid} onChange={e => setNewAzureGuid(e.target.value)} /></td>
                        </tr>
                        <tr>
                            <td>User Login</td>
                            <td><input type="text" value={newUserLogin} onChange={e => setNewUserLogin(e.target.value)} /></td>
                        </tr>
                        <tr>
                            <td>User Name</td>
                            <td><input type="text" value={newUserName} onChange={e => setNewUserName(e.target.value)} /></td>
                        </tr>
                        <tr>
                            <td>Is Edge User</td>
                            <td><input type="checkbox" checked={newIsEdgeUser} onChange={e => setNewIsEdgeUser(e.target.checked)} /></td>
                        </tr>
                        <tr>
                            <td>Is Edge Admin</td>
                            <td><input type="checkbox" checked={newIsEdgeAdmin} onChange={e => setNewIsEdgeAdmin(e.target.checked)} /></td>
                        </tr>
                    </tbody>
                </table>
                <button className="button" onClick={() => AddNewUser()}>Save</button>
                <button className="button" onClick={() => CloseAdd()}>Cancel</button>
            </div>

    return (
        <div className="contentArea">
            {addForm}
            <div>
                {userMsg}              
                <Styles>
                    {userTable}
                </Styles>
            </div>
        </div>
    )
}