import React, { useState, useEffect } from 'react';
import { MODE, SERVICE_URL } from './Shared';

export function ChargePanel(props) {

    const [selectedBillableItemCategory, setSelectedBillableItemCategory] = useState(0);
    const [currentBillableItems, setCurrentBillableItems] = useState(null);
    const [selectedBillableItem, setSelectedBillableItem] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [amount, setAmount] = useState(0.00);
    const [notes, setNotes] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);
    const { pointOfSale, eventRegistrationId, paymentMethods, allBillableItems, billableItemCategories, showBill, userInfo } = props;
  

    useEffect(() => {
        if (allBillableItems && allBillableItems !== null) {
          const currents = allBillableItems.filter((bi) => {
            return bi.billableItemCategoryId === 1;
          });
          setCurrentBillableItems(currents);
        }
    }, [allBillableItems]);

    useEffect(() => {
        if (paymentMethods && paymentMethods !== null && selectedPaymentMethod === 0) {
          setSelectedPaymentMethod(paymentMethods[0].paymentMethodId);
        }
    }, [paymentMethods, selectedPaymentMethod])

    useEffect(() => {
        if (billableItemCategories && billableItemCategories !== null && selectedBillableItemCategory === 0) {
          setSelectedBillableItemCategory(billableItemCategories[0].billableItemCategoryId);
        }        
    }, [billableItemCategories, selectedBillableItemCategory]);


    useEffect(() => {
        if (allBillableItems !== null && selectedBillableItemCategory > 0) {
            let currBis = []
            allBillableItems.forEach((bi) => {
              if (bi.billableItemCategoryId === selectedBillableItemCategory) {
                currBis.push(bi);
              }
            });
            setCurrentBillableItems(currBis);
            setSelectedBillableItem(currBis[0].billableItemId);
        }
    }, [allBillableItems, selectedBillableItemCategory]);

    useEffect(() => {
        if (currentBillableItems && currentBillableItems !== null && selectedBillableItem !== null) {
            let selectedBI = null;
            currentBillableItems.forEach((cbi) => {
              if (cbi.billableItemId === selectedBillableItem) {
                selectedBI = cbi;
              }
            })
            setNotes(selectedBI.billableItemDescription);
            setAmount(selectedBI.defaultAmount);
        }        
    }, [currentBillableItems, selectedBillableItem]);

    function SaveCharge() {
      const body = JSON.stringify({
        "EventRegistrationId" : !pointOfSale ? eventRegistrationId : 1,
        "BillableItemId" : selectedBillableItem,
        "Quantity" : parseInt(quantity), 
        "Amount": parseFloat(amount),
        "Notes" : notes,
        "PaymentMethodId": selectedPaymentMethod,
        "UserName" : "programming",     
        "TestMode" : MODE === "TEST",
        "Requestor" : userInfo.userName
      });
        const url = SERVICE_URL + "/saveCharge";
        
            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
              if (response.ok) {
                //const msg = pointOfSale ? "Point of Sale Charge and Payment Successfully Created!" : "Charge Successfully Created!";
                //alert(msg);
                if (!pointOfSale) {
                  showBill(); 
                } else {                  
                  setSelectedBillableItemCategory(0);
                  setSelectedPaymentMethod(paymentMethods[0].paymentMethodId);
                  setShowConfirmation(true);
                  setQuantity(1);
                  setTimeout(() => setShowConfirmation(false), 3000);
                  clearTimeout();
                }
              } else {
                alert("An error has occurred.  Please see Event Logs for more detail.")
              }
            });
    }

    const cateogrySelect = billableItemCategories === undefined || billableItemCategories === null ? <div /> : 
      <select width="75px" onChange={(event) => setSelectedBillableItemCategory(parseInt(event.target.value))} value={selectedBillableItemCategory}> {
        billableItemCategories.map((bic) => {
          return <option key={bic.billableItemCategoryId} value={bic.billableItemCategoryId}>{bic.billableItemCategoryName}</option>
          })
        }
      </select>;

    const billableItemSelect = currentBillableItems === undefined || currentBillableItems === null ? <div /> : 
        <select width="75px" onChange={(event) => setSelectedBillableItem(parseInt(event.target.value))}> {
          currentBillableItems.map((bi) => {
            return <option key={bi.billableItemId} value={bi.billableItemId}>{bi.billableItemName}</option>
            })
        }
    </select>;

    const paymentMethodSelect = paymentMethods === undefined || paymentMethods === null ? <div /> : 
      <select width="75px" onChange={(event) => setSelectedPaymentMethod(parseInt(event.target.value))} value={selectedPaymentMethod}> {
        paymentMethods.map((pm) => {
          return <option key={pm.paymentMethodId} value={pm.paymentMethodId}>{pm.paymentMethodName}</option>
          })
        }
      </select>;    
    const total = quantity * amount;
    const paymentMethod = !pointOfSale ? <div /> : (<tr>
            <td>
            Payment Method:
            </td>
            <td>
            {paymentMethodSelect}
            </td> 
          </tr>
        ); 
    const buttonLabel = pointOfSale ? "Save POS Transaction" : "Apply Charge";
    return (
        <div className="checkin-content-tabbed"> 
          <table>
            <tbody>
              <tr>
                <td>
                  Category:
                  </td>
                  <td>
                  {cateogrySelect}
                </td>
              </tr>
              <tr>
                <td>
                  Item:
                </td>
                <td>
                  {billableItemSelect}
                </td>
              </tr>
              <tr>
                <td>
                 Quantity:
                 </td>
                 <td>
                <input type="number" value={quantity} onChange={e => setQuantity(e.target.value)} />
                </td>
              </tr>
              <tr>
                <td>
                Amount:
                </td>
                <td>
                <input type="number" value={amount.toLocaleString('en-US', {
                  style: 'decimal',
                  minimumFractionDigits: 2
                    })} onChange={e => setAmount(e.target.value)} />
                </td>
              </tr>
              <tr>
                <td>
                  Notes:
                </td>
                <td>
                  <textarea name="notes" rows="5" cols="100" value={notes} onChange={e => setNotes(e.target.value)} />
                </td>
              </tr>
              {paymentMethod}
              <tr>
                <td>
                Charge Total: 
                </td>
                <td>{total.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                </td>
              </tr>
            </tbody>
          </table>    
        

            <button className='button' onClick={SaveCharge}>{buttonLabel}</button>  
            <div>{showConfirmation ? <label style={{color: "red"}}>Point of Sale Charge has been saved</label> : <div />}</div>
        </div>

    )
}