import React, { useState, useEffect, useCallback } from 'react';
import { MODE, SERVICE_URL } from './Shared';

export function PaymentPanel(props) {

    const { eventRegistrationId, outstandingBalance, paymentMethods, showBill, userInfo } = props;
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [paymentAmount, setPaymentAmount] = useState(0.00);
    const [notes, setNotes] = useState("");
    const [enableEdit, setEnableEdit] = useState(false);
    const [amountInput, setAmountInput] = useState(<div />);
    const [charges, setCharges] = useState(null);   
    const [buttonText, setButtonText] = useState("Apply Payment");
    const [responseMsg, setResponseMsg] = useState("");

    

    useEffect(() => {
        if (paymentMethods && paymentMethods !== null) {
          setSelectedPaymentMethod(paymentMethods[0].paymentMethodId);
        }
    }, [paymentMethods])

    const GetCharges = useCallback(() => {
      setResponseMsg("Loading Charges...");
      const url = SERVICE_URL + "/getCharges";
      const body = JSON.stringify({
        "EventRegistrationId": eventRegistrationId,
        "ChargeId" : -1,
        "BillableItemId" : -1,
        "Unpaid" : true,
        "TestMode" : MODE === "TEST",
        "Requestor" : userInfo.userName
      });
      fetch(url, {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json'
        }}).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 406) {
              setResponseMsg("No Charges found.");
            } else {
              setResponseMsg("There was a problem with the request for Event data.");
            }
            
            setCharges(null);
          }
      })
      .then((responseJson) => {
        if (responseJson && responseJson !== null) {
          setResponseMsg("");
          setCharges(responseJson);
        }         
      });
  }, [eventRegistrationId, userInfo.userName]);

  const updateCharges = useCallback((value, id) => {
    var result = [...charges];
    result = result.map((x) => { 
      if (x.chargeId === id) {
        if (value > x.balance) {
          x.fieldError = "Value is more than amount due!";
          x.payAmount = value;
        } else {
          x.fieldError = "";
          x.payAmount= value;
        }        
      };
      return x;
    });
    setCharges(result);
  }, [charges]);
    useEffect(() => {

        setPaymentAmount(outstandingBalance);            

        if (charges === null) {
            GetCharges();
        }       
    }, [paymentMethods, charges, outstandingBalance, GetCharges ])

/*     useEffect(() => {
      setAmountInput(<label>{paymentAmount.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2})}</label>);
    }, [paymentAmount]); */

    useEffect(() => {
        if (enableEdit) {
          if (outstandingBalance <= 0) { 
            setAmountInput(<input type="number" value={paymentAmount.toLocaleString('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2})} onChange={e => setPaymentAmount(e.target.value)} />);
          } else {
            if (charges !== null) {
              let finalCharges = [];
              if (charges[0].partyCharge > 0) {
                charges.forEach((chg) => {
                  if (!finalCharges.some(function (orig) {
                    return chg.billableItemName === orig.billableItemName;
                  })) {
                    finalCharges.push(chg);
                  }
                })
              } else {
                finalCharges = charges;
              } 
              const chargeList = finalCharges.map((chg) => {
                if (chg.balance > 0.00) {
                  return (
                    <div>
                      <table>
                        <tr>
                          <td>
                  <label>{chg.billableItemName}: ${chg.balance.toLocaleString('en-US', {style: 'decimal',minimumFractionDigits: 2})}
                    <input type="number" min="0" step=".01" value={chg.payAmount} onChange={(e) => updateCharges(e.target.value, chg.chargeId)}/>
                    {chg.fieldError}
                    </label>
                        </td>
                      </tr>
                    </table>
                  </div>
                )
              } else {
                return ( <div />);
              }
            }
              );              
                setAmountInput(chargeList);             
            }
          }          
        } else {
          if (charges && charges !== null) {
            let finalCharges = [];
            if (charges[0].partyCharge > 0) {
              charges.forEach((chg) => {
                if (!finalCharges.some(function (orig) {
                  return chg.billableItemName === orig.billableItemName;
                })) {
                  finalCharges.push(chg);
                }
              })
            } else {
              finalCharges = charges;
            } 
            const chargeList = finalCharges.map((chg) => {
              if (chg.balance > 0.00) {
                return (
                  <div>
                    <table>
                      <tr>
                        <td>
                          <label>{chg.billableItemName}:</label>
                        </td>
                        <td>
                          <label>${chg.balance.toLocaleString('en-US', {style: 'decimal',minimumFractionDigits: 2})}
                    </label>
                        </td>
                      </tr>
                    </table>
                  </div>
                )
              } else {
                return ( <div />);
              }
            }
              );
              setAmountInput(chargeList);             
          }
        }
    }, [enableEdit, charges, outstandingBalance, paymentAmount, updateCharges]);

    function SavePayment() {
      setButtonText("Applying...");
      //make sure there aren't any errors
      const atLeastOneError = charges && charges.some((charge) => {
        return charge.fieldError && charge.fieldError !== "";
      });
      if (atLeastOneError) {
        setButtonText("Apply Payment");
        alert("Please correct all charge errors!");
      } else {
        //if this is a breakout payment
        if (outstandingBalance > 0) {
          const body = JSON.stringify({
            "EventRegistrationId" : eventRegistrationId,
            "Charges" : charges,
            "BillableItemId" : -1,
            "PaymentAmount": parseFloat(paymentAmount),
            "PaymentMethodId": selectedPaymentMethod,
            "OutstandingBalance" : outstandingBalance,
            "FullPayment" : !enableEdit,
            "Notes" : notes,
            "UserName" : "programming",     
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });
            const url = SERVICE_URL + "/savePartialPayment";
            
                fetch(url, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                  if (response.ok) {
                    //alert("Payment Successfully Created!");
                    showBill();
                  } else {
                    alert("An error has occurred.  Please see Event Logs for more detail.")
                  }
                });
          

        } else {
          const body = JSON.stringify({
            "EventRegistrationId" : eventRegistrationId,
            "BillableItemId" : -1,
            "PaymentAmount": parseFloat(paymentAmount),
            "PaymentMethodId": selectedPaymentMethod,
            "OutstandingBalance" : outstandingBalance,
            "Notes" : notes,
            "UserName" : "programming",     
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });
            const url = SERVICE_URL + "/saveFullPayment";
            
                fetch(url, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                  if (response.ok) {
                    //alert("Payment Successfully Created!");
                    showBill();
                  } else {
                    alert("An error has occurred.  Please see Event Logs for more detail.")
                  }
                });
        }
      }        
    }

    const paymentMethodSelect = paymentMethods === undefined || paymentMethods === null ? <div /> : 
      <select width="75px" onChange={(event) => setSelectedPaymentMethod(parseInt(event.target.value))}> {
        paymentMethods.map((pm) => {
          return <option key={pm.paymentMethodId} value={pm.paymentMethodId}>{pm.paymentMethodName}</option>
          })
        }
      </select>;  
    
    const balanceLabel = outstandingBalance > 0.0 ? "There is an outstanding balance of $" + outstandingBalance.toLocaleString('en-US', {style: 'decimal',minimumFractionDigits: 2}) + " on this account" : "";
    const editBoxLabel = outstandingBalance <= 0 ? "Apply Payment without Balance" : "Apply Less Than Amount Due";   
    if (!charges || charges === null) {
      return (
        <div>
          {responseMsg}
        </div>
      )
    } else {
    return (      
        <div>
            <div>
                <label>{balanceLabel}</label>
            </div>
            <div>
              <label>
                {editBoxLabel}
                <input type="checkbox" checked={enableEdit} onClick={(e) => setEnableEdit(e.target.checked)}/>
              </label>
            </div>
            <table>
              <tbody>
                <tr>
                  <td>
                    Payment Amount:
                  </td>
                  <td>
                    {amountInput}
                  </td>
                </tr>
                <tr>
                  <td>
                    Payment Method:
                  </td>
                  <td>
                    {paymentMethodSelect}
                  </td>
                </tr>
                <tr>
                  <td>
                    Notes:
                  </td>
                  <td>
                    <textarea rows="5" cols="100" onChange={(e) => setNotes(e.target.value)}/>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
                <button className='button' onClick={SavePayment} disabled={buttonText !== "Apply Payment"}>{buttonText}</button> 
            </div>            
        </div>
      )
    }

}