import React from 'react';
//import { Redirect, Route, Switch } from 'react-router-dom';

export default function Footer() {
    const copyrightYear = new Date().getFullYear();
    return (
        <div className="footer">
           © {copyrightYear} - Star Island Corporation. Icons on Home Page provided by Icon8 https://icons8.com
        </div>
    );
}