import React, {useState, useEffect, useCallback } from 'react';
import Footer from './Footer';
import Header from './Header';
import OVHome from './OVHome';
import {MODE, SERVICE_URL, DEVLOCAL} from '../controls/Shared';


export default function Container() {

    const [showHome, setShowHome] = useState(false); 
    const [azureUser, setAzureUser] = useState(null);
    const [user, setUser] = useState(null); 
    const [responseMsg, setResponseMsg] = useState("");

    
    const testBanner = MODE === "TEST" ? <div className="banner"><label className="warningText">WARNING! YOU ARE IN TEST MODE!</label></div> : <div />;       
    const authUrl = MODE === "TEST" ? 'https://edge-test.starisland.org/.auth/me' : 'https://edge.starisland.org/.auth/me';

    const getAzureUser = useCallback(async() => {
        await fetch(authUrl).then((response) => {
             if (response.ok) {
               return response.json();
             } else {
               return null;
             }
         })
         .then((responseJson) => {
             setAzureUser(responseJson.clientPrincipal);
         })
         .catch((error) => {
             console.log(error);
         });
          //test code to set user
          if (DEVLOCAL) {
              let testUser = {};
            testUser.userName = "Jackson Boomhower";
            testUser.userDetails = "jboomhower@starisland.org";
            setAzureUser(testUser);   
        }  
     }, [authUrl]);

     const getUserData = useCallback(() => {
        const url = SERVICE_URL + "/getUserByGuid";

        const body = JSON.stringify({
            "AzureGuid" : azureUser.userId,
            "UserLogin" : azureUser.userDetails,
            "TestMode" : MODE === "TEST"
        });

        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
            'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                setResponseMsg("There was a problem with the request. See Event Logs.");      
                return null;
            }
        })
        .then((responseJson) => {
            setUser(responseJson[0]);
        });  
    }, [azureUser]);
 
     useEffect(() => {
         getAzureUser();           
     }, [getAzureUser]);

     useEffect(() => {
        if (azureUser && azureUser !== null) {
            getUserData();
        }
     }, [azureUser, getUserData])
    
    const mainContent = user && user !== null && user.isEdgeUser ? <OVHome showHome={showHome} setShowHome={setShowHome} userInfo={user}/> : <div />;

    return (
        <div className="mainArea">
            
            <Header setShowHome={setShowHome} user={user}/>
            {testBanner}
            {responseMsg}
            {mainContent}
            <Footer />
        </div>

    );

}